import React, { useState } from 'react';
import './Pay.css';
import logo from '../../img/logo_uam.png'
import wave from '../../img/wave.jpg'
import om from '../../img/OM.jpg'
import axios from 'axios';
import freemoney from '../../img/free-money.jpg'
import expresso from '../../img/expresso.jpg'
import bank from'../../img/carte-bancaire-50px.svg'
import { Image, Form,Container,Button,Spinner } from 'react-bootstrap';
import IdTransaction from '../../components/idTransaction/IdTransaction';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import AlerteMessage from '../../components/AlerteMessage';
import { useEffect } from 'react';

function PayAdmission ({prix=10000}) {
    const [data, setData] = useState('');
    const [show, setShow] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [checked, SetChecked] = useState(false);
    const [open, setOpen] = useState (false);
    const [message, setMessage] = useState (false)
    const [valid, SetIsValid] = useState({
        isValidEmail: false,
        isValidPhoneNumber: false,
      });
    const handleChange = (e) => {
        const name= e.target.name;
        const value = e.target.value;
        // setData({ ...data, [name]: value }); //clé valeur du champs
        setData((prevData) => ({ ...prevData, [name]: value, pays: prix }));
        if (name === 'telephone' ) {
            const phoneRegex = /^\d{1,25}$/
;  //validation numéro de téléphone
            SetIsValid((valid) => ({
                ...valid,
                isValidPhoneNumber: phoneRegex.test(value),
            }));
        }
        if (name === 'email') {
            //validation email
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            SetIsValid((valid) => ({
                ...valid,
                isValidEmail: emailRegex.test(value),
            }));
        }
        if (name === 'operateur') {
            SetChecked(e.target.checked)
        }
    }
    const handleClose3 = () => {
        setOpen(false)
    }
    let NotComplete = true;
    if (!valid.isValidEmail || !valid.isValidPhoneNumber || !checked) {
        NotComplete = false;
    }
    useEffect(() => {
        // Chargement du script touchpay
        const script = document.createElement('script');
        script.src = 'https://touchpay.gutouch.net/touchpayv2/script/touchpaynr/prod_touchpay-0.0.1.js';
        script.type = 'text/javascript';
        script.async = true;
        document.head.appendChild(script);
        return () => {
          document.head.removeChild(script);
        };
      }, []);
      const callTouchPay = () => {
        if (window.sendPaymentInfos) {
            console.log(prix.nationnalite)
            if (prix.nationnalite ==='66') {
                prix.nationnalite = 202500
            } else {
                prix.nationnalite =25313
            }
            var id=new Date().getTime()
            // alert(id)
            data['amount'] = id
          console.log(data.operateur,data.pays,data.amount)
          axios({
            url:'https://depot.uam.sn/api/v1/paiement/',
            method: 'POST',
            data:data
        })
        .then(result => {
            console.log("Initialisation Success");
            console.log('Data from API :', result.data);
            window.sendPaymentInfos(
                id,
                'UAMM19171',
                'DA3OxeOUcWHCn7Bu5O7AfIzfPVFHhEXuRIVQnPo6Gkb9qn6puM',
                'uamm.sn',
                `https://depot.uam.sn/`,
                'https://depot.uam.sn',
               prix.nationnalite,
                'Dakar',
                `${data.email}`,
                prix.prenom,
                prix.nom,
                `${data.telephone}`
              );
            if (result.data.message==='OK') {
                console.log(result.data)
            }
        })
        .catch((error) => {
            console.error(error)
        });
          console.log()
        } else {
          console.error('sendPaymentInfos is not defined');
        }
      };
   // console.log("Not completed " +NotComplete)
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        // setData({ ...data, amount: prix });
        data['campagne'] = 'admission'
        console.log("Send to API "+data)
        axios({
            url:'https://depot.uam.sn/api/v1/paiement/',
            method: 'POST',
            data:data
        })
        .then(result => {
            console.log("Initialisation Success");
            console.log('Data from API :', result.data);
            if (result.data.message==='OK') {
                console.log(result.data)
                setSubmitted(false);
                setShow(true);
                if (result.data.paymentURL) {
                    window.location.href = result.data.paymentURL;
                //    window.open(result.data.paymentURL, "_blank");
                }
            }
            //window.location.href = result.data.paymentURL;
        })
        .catch((error) => {
            if (error.response.status === '500') {
                setOpen(true)
                setMessage('Impossible d\'initier un paiement ')
            } else {
                if (error.response.data.detail) {
                    setOpen(true)
                    setMessage('Impossible d\'initier un paiement ')
                }
            }
            // console.error('Error:', error);
            setSubmitted(false);
        });
    }
    return (
        <>
        <AlerteMessage open={open} handleClose={handleClose3} message={message} />
        <IdTransaction show={show} email={data.email} handleClose={() => 
            {setShow(false)}
            } />
        <Container className='pay' id='main'>
            <div className='d-flex flex-row' id='payHead'>
                <div>
                    <Image src={logo} width={239} height={220} />
                </div>
                <div className='' id='texthead'>
                    <p>Université Amadou Mahtar Mbow </p>
                    <p>Année Académique 2023-2024</p>
                </div>
            </div>
            <div>
                <Form onSubmit={handleSubmit} className=''>
                    <div className='p-4 row'>
                        <Form.Group className='col-md-6 col-sm-12'>
                            <Form.Control 
                                placeholder='Numero de Telephone'
                                size='md'
                                type='number'
                                name='telephone'
                                className='p-2 custom-email-input'
                                onChange={handleChange}
                                autoFocus
                                required
                            />
                        </Form.Group>
                        <Form.Group className='col-md-6 col-sm-12'>
                            <Form.Control 
                                placeholder='Email'
                                size='md'
                                type='email'
                                name='email'
                                className='p-2 custom-email-input'
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <div>
                        <p className='text-danger ml-2' id='frais'>Le paiement des droits d'inscription, non remboursables, sont de dix mille (10 000) F. CFA + frais de 125 F. CFA</p>
                    </div>
                    </div>
                    <Form.Group>
                        <Form.Check
                            inline
                            onChange={handleChange}
                            type="radio"
                            id="wave"
                            name="operateur"
                            value="wave"
                            label = {
                                <Image src={wave} height={80} width={80} alt="wave" />
                            }
                        />
                        <Form.Check
                            inline
                            onChange={handleChange}
                            type="radio"
                            id="wave"
                            name="operateur"
                            value="orange-money"
                            label = {
                                <Image src={om} height={80} width={80} alt="wave" />
                            }
                        />
                        <Form.Check
                            inline
                            onChange={handleChange}
                            type="radio"
                            id="wave"
                            name="operateur"
                            value="free-money"
                            label = {
                                <Image src={freemoney} height={80} width={80} alt="wave" />
                            }
                        />
                        <Form.Check
                            inline
                            onChange={handleChange}
                            type="radio"
                            id="wave"
                            name="operateur"
                            value="e-money"
                            label = {
                                <Image src={expresso} height={80} width={80} alt="wave" />
                            }
                        />
                        {/* <Form.Check
                            inline
                            onChange={handleChange}
                            type="radio"
                            id="wave"
                            name="operateur"
                            value="mobilebank"
                            label = {
                                <Image src={bank} height={80} width={80} alt="cartebancaire" />
                            }
                        /> */}
                    </Form.Group>
                   
		
		{/* <input onClick={callTouchPay} className='intouch' type='button'  value='Payer avec carte'/> */}

                    <Button
                        onClick={data.operateur === 'mobilebank' ? callTouchPay : handleSubmit}
                        variant='primary' 
                        disabled={!valid.isValidEmail || !valid.isValidPhoneNumber || !checked} type='button'
                        className ='w-75 btn-submit'
                        >
                            {NotComplete ? (<span>Payer avec {data.operateur}</span>)  : <FontAwesomeIcon icon={faBan} className='color-logo' />} 
                            {submitted ? 
                                <Spinner animation="grow"
                                    size="sm" role="status"
                                >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> 
                            : null} 
                    </Button>
                </Form>
            </div>
        </Container>
        </>
    )
}
export default PayAdmission
