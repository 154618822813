import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Cookies from "js-cookie";
import {useEffect} from "react";


const Protected = ({ children }) => {
    const {auth, setAuth} = useAuth();
    const navigate = useNavigate()

    useEffect(() => {
        if (!auth?.accessToken){
            navigate('/connexion');
        }
    })

  return children;
};
export default Protected;