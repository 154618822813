import { Form } from "react-bootstrap"
import { useEffect,useState } from "react"
export default function Input ({type,name,min,max,onChange,label,className,id,value,focus,readOnly,defalut,required=true,placeholder=''}) {
    return (
      <>
          <Form.Group className={className} id={id}>
                  <Form.Label style={{color:'#FFD524'}} className='fw-medium'> {required ? <>{label.charAt(0).toUpperCase() + label.slice(1)} <span style={{color:'red'}}>*</span></> : <>{label.charAt(0).toUpperCase() + label.slice(1)}</>}</Form.Label>
                    <Form.Control
                      className={className}
                      size='md'
                      step={type==="number" ? 0.01 :null }
                      readOnly={readOnly}
                      min={min}
                      autoFocus={focus}
                      required = {required}
                      value={value}
                      defaultValue={defalut}
                      max={max}
                      type={type}
                      name={name}
                      placeholder= {placeholder}
                      onChange={onChange}
                    />
          </Form.Group>
      </>
    )
  }

export  function InputF ({type,name,onChange,label,className,id,message,required,value,ref, accept='.pdf,.png,.jpeg,.jpg'}) {
    return (
        <>
            <Form.Group className={className} >
                <Form.Label style={{color:'#FFD524'}} className='fw-medium'>{required ? <>{label.charAt(0).toUpperCase() + label.slice(1)} <span style={{color:'red'}}>*</span></> : <>{label.charAt(0).toUpperCase() + label.slice(1)}</>}</Form.Label>
                    <Form.Control
                        className={className}
                        size='md'
                        id={id}
                        ref={ref}
                        value={value}
                        accept={accept}
                        type={type}
                        name={name}
                        required = {required}
                        placeholder={label.charAt(0).toUpperCase() + label.slice(1)}
                        onChange={onChange}
                    />
            </Form.Group>
        </>
    )
}

export function InputT ({type,name,min,max,onChange,label,className,id,defalut,value}) {
    return (
      <>
          <Form.Group className={className} id={id}>
                  <Form.Label className='fw-medium'>{label.charAt(0).toUpperCase() + label.slice(1)}</Form.Label>
                    <Form.Control
                      className={className}
                      size='md'
                      required
                      min={min}
                      max={max}
                      value={value}
                      defaultValue={defalut}
                      type={type}
                      name={name}
                      placeholder={label.charAt(0).toUpperCase() + label.slice(1)}
                      onChange={onChange}
                    />
          </Form.Group>
      </>
    )
  }

export function InputCheckbox ({value, label, onChange}) {
    return (
      <Form.Check 
      inline
      type='radio'
      id={`default-checkbox}`}
      value={value}
      name="L1"
      onChange={onChange}
      label={`${label}`}
    />
    )
}
export function InputRadio ({value, label, onChange,name='L1'}) {
    return (
      <Form.Check 
      inline
      type='radio'
      id={`default-checkbox}`}
      value={value}
      name={name}
      onChange={onChange}
      label={`${label}`}
    />
    )
}
export function InputCheckB ({value, label, onChange,name='Lm1'}) {
    return (
      <Form.Check 
      type='checkbox'
      id={`default-checkbox}`}
      value={value}
      name={name}
      onChange={onChange}
      label={`${label}`}
    />
    )
}

export const InputCheck = () => {
    const [selectedCount, setSelectedCount] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState([]);
  
    const handleCheckboxChange = (e) => {
      const isChecked = e.target.checked;
      const newCount = isChecked ? selectedCount + 1 : selectedCount - 1;
      const optionValue = e.target.value;
  
      if (newCount <= 3) {
        setSelectedCount(newCount);
        if (isChecked) {
          setSelectedOptions([...selectedOptions, optionValue]);
        } else {
          setSelectedOptions(selectedOptions.filter(option => option !== optionValue));
        }
      } else {
        e.target.checked = false;
      }
    };
    useEffect(() => {
      console.log(selectedOptions)
    }, [selectedOptions])
  
    return (
      <div>
        <InputCheckbox value="HEEG" label="HEEG" onChange={handleCheckboxChange}/>
        <InputCheckbox value="ESTI" label="ESTI" onChange={handleCheckboxChange}/>
        <InputCheckbox value="ES2A" label="ES2A" onChange={handleCheckboxChange}/>
        <InputCheckbox value="ESMGE" label="ESMGE" onChange={handleCheckboxChange}/>
        <InputCheckbox value="ESU2A" label="ESU2A" onChange={handleCheckboxChange}/>
      </div>
    );
  };
  export function InputOption ({value,label}) {
    return (
      <option value={value}>{label}</option> 
    )
  }