import { Button, } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect,useState } from "react";
import {Form,Spinner, Modal} from 'react-bootstrap';
import axios from "axios";
import Graphe from "../../../components/Graphes/Graphes";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);



function DashboardHome(){
    const[ civilite, setCivilite] = useState({
      'M':'0',
      'F':0
    })
    
    const [data, setData] = useState([]);
    const [ecole, setEcole] = useState('');
    const [dataSize, setDataSize] = useState(0);
    const [statEcole, setStatEcole] = useState({
      'student': '0',
      'teacher': '0',
      'ES2A': '0',
      'ESU2A': '0',
      'ESMGE': '0'
    })
    const [centre, setCentre] = useState({
      'Diamniadio': '0',
      'Diourbel': '0',
      'Kolda': '0',
      'Ziguinchor': '0',
      'Fatick': '0',
      'Kédougou': '0',
      'Louga': '0',
      'Matam': '0',
      'Tambacounda': '0',
      'Podor': '0',
      'Thiès':'0',
      'Kaolack':'0'
    })
    const handleChange = (event) => {
      const { name, value } = event.target;
      setEcole(value)
    };
    const [sizecentre, setsizecentre] = useState('')
    const url = `https://depot.uam.sn/api/v1/backup/recrutement?type_poste=${ecole}`;
    // const [stats, setStat] = useState([])
    // const [statsV, setStatV] = useState([])
    useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://depot.uam.sn/api/v1/recrutement/stats');
        console.log(response)
        // const size = await axios.get('https://depot.uam.sn/api/v1/recrutement/');
        if (response.data.ecole_postulee) {
           const teaching = response?.data?.ecole_postulee?.Teachning !== undefined ? response?.data?.ecole_postulee?.Teachning : 0;
           const student = response?.data?.ecole_postulee?.Student !== undefined ? response?.data?.ecole_postulee?.Student : 0;
           setStatEcole(prevState => ({
          ...prevState,
          'student': student
          }));
           setStatEcole(prevState => ({
          ...prevState,
          'teacher': teaching
          }));
           setDataSize(student + teaching)
        }

        // setData("Statistiques " +response.data);
        
       
        // setCentre(prevState => ({
        //   ...prevState, 'Thiès' : response.data.centre_examen.Thiès
        // }));
        // console.log("Centre  ",response.data.centre_examen)
        // const premierChoixES = response?.data?.premier_choix_ecole?.ESTI !== undefined ? response?.data?.premier_choix_ecole?.ESTI : 0;
        // const secondChoixES = response?.data?.second_choix_ecole?.ESTI !== undefined ? response?.data?.second_choix_ecole?.ESTI : 0;
        // const troisiemeChoixES = response?.data?.troisieme_choix_ecole?.ESTI !== undefined ? response?.data?.troisieme_choix_ecole?.ESTI : 0;
        // const ESTI = premierChoixES + secondChoixES + troisiemeChoixES;
        // // console.log("Somme postulé ESTI: " + ESTI);
        // setStatEcole(prevState => ({
        //   ...prevState,
        //   'ESTI': ESTI
        // }));
        // const premierChoixH = response?.data?.premier_choix_ecole?.HEEG !== undefined ? response?.data?.premier_choix_ecole?.HEEG : 0;
        // const secondChoixH = response?.data?.second_choix_ecole?.HEEG !== undefined ? response?.data?.second_choix_ecole?.HEEG : 0;
        // const troisiemeChoixH = response?.data?.troisieme_choix_ecole?.HEEG !== undefined ? response?.data?.troisieme_choix_ecole?.HEEG : 0;
        // const HEEG = premierChoixH + secondChoixH + troisiemeChoixH;
        // // console.log("Somme postulé HEEG: " + HEEG);
        // setStatEcole(prevState => ({
        //   ...prevState,
        //   'HEEG': HEEG
        // }));
        // const premierChoixES2 = response?.data?.premier_choix_ecole?.ES2A !== undefined ? response?.data?.premier_choix_ecole?.ES2A : 0;
        // const secondChoixHES2 = response?.data?.second_choix_ecole?.ES2A !== undefined ? response?.data?.second_choix_ecole?.ES2A : 0;
        // const troisiemeChoixHES2 = response?.data?.troisieme_choix_ecole?.ES2A !== undefined ? response?.data?.troisieme_choix_ecole?.ES2A : 0;
        // const ES2A = premierChoixES2 + secondChoixHES2 + troisiemeChoixHES2;
        // // console.log("Somme postulé ES2A: " + ES2A);
        // setStatEcole(prevState => ({
        //   ...prevState,
        //   'ES2A': ES2A
        // }));
        // const premierChoixE = response?.data?.premier_choix_ecole?.ESU2A !== undefined ? response?.data?.premier_choix_ecole?.ESU2A : 0;
        // const secondChoixE = response?.data?.second_choix_ecole?.ESU2A !== undefined ? response?.data?.second_choix_ecole?.ESU2A : 0;
        // const troisiemeChoixE = response?.data?.troisieme_choix_ecole?.ESU2A !== undefined ? response?.data?.troisieme_choix_ecole?.ESU2A : 0;
        // const ESU2A = premierChoixE + secondChoixE + troisiemeChoixE;
        // // console.log("Somme postulé ESU2A: " + ESU2A);
        // setStatEcole(prevState => ({
        //   ...prevState,
        //   'ESU2A': ESU2A
        // }));
        // const premierChoixESM = response?.data?.premier_choix_ecole?.ESMGE !== undefined ? response?.data?.premier_choix_ecole?.ESMGE : 0;
        // const secondChoixESM = response?.data?.second_choix_ecole?.ESMGE !== undefined ? response?.data?.second_choix_ecole?.ESMGE : 0;
        // const troisiemeChoixESM = response?.data?.troisieme_choix_ecole?.ESMGE !== undefined ? response?.data?.troisieme_choix_ecole?.ESMGE : 0;
        // const ESMGE = premierChoixESM + secondChoixESM + troisiemeChoixESM;
        // // console.log("Somme postulé ESMGE: " + ESMGE);
        // setStatEcole(prevState => ({
        //   ...prevState,
        //   'ESMGE': ESMGE
        // }));
        // const filles = response?.data?.civilite?.F !== undefined ? response?.data?.civilite?.F: 0;
        // setCivilite(prevState => ({
        //   ...prevState, 
        //   'F' : filles
        // }))
        // const hommes = response?.data?.civilite?.M !== undefined ? response?.data?.civilite?.M: 0;
        // setCivilite(prevState => ({
        //   ...prevState, 
        //   'M' : hommes
        // }))
      } catch (error) {
        console.error('Une erreur s\'est produite lors de la récupération des données:', error);
      }
    };

    fetchData();
  }, [])
    return (

        <div className="container mt-3 mb-3">
            <p className="body">DashBoard Home</p>
            <div className="p-3 mt-2 " style={{padding : '12px', width : '20%', border : '2px solid #fff', boxShadow : '0px 0px 10px rgba(0, 0, 0, 0.2)'}}>
                    <div className="">
                    <h6 style={{color : '#000', marginBottom : '20px', opacity : '0.6'}} className="">Dossiers <br /> </h6>
                    <span className=" text-center fw-bold h2 mb-4 p-3" style={{marginBottom : '20px'}} >{dataSize}</span> <br />
                    <span className=" text-center mb-4 p-3" style={{marginBottom : '20px'}} >candidats</span>
                    </div>
            </div>
            {/* <Form.Group>
              <Form.Label className='fw-medium'>Choisir un centre<span style={{color:'red'}}>*</span></Form.Label>
              <Form.Select name='region' required value={ecole} onChange={handleChange}>
                <option value="">Choisir un centre</option>
                <option value="Diamniadio (UAM)">Diamniadio (UAM)</option>
                <option value="Diourbel">Diourbel</option>
                <option value="Kaolack">Kaolack</option>
                <option value="Kédougou">Kédougou</option>
                <option value="Matam">Matam</option>
                <option value="Saint-Louis">Saint-Louis</option>
                <option value="Tambacounda">Tambacounda</option>
                <option value="Thiès">Thiès</option>
                <option value="Ziguinchor">Ziguinchor</option>
                <option value="Podor">Podor</option>
                <option value="Louga">Louga</option>
                <option value="Kolda">Kolda</option>
              </Form.Select>
           </Form.Group> */}
           {/* {sizecentre}
           <br />
            <a href={url} className="btn btn-primary">
            <div>  
                Extraire {ecole}
            </div>
            </a>

            
            
             <div className="p-3 mt-2 bg-info bg-opacity-10 border border-success border-start-0 rounded-end">
                    <h6>Candidats postulés pour ESTI : <span className="">{statEcole['ESTI'] ? statEcole['ESTI'] : 0}</span></h6>
            </div>
           <div className="p-3 mt-2 bg-info bg-opacity-10 border border-success border-start-0 rounded-end">
                    <h6>Candidats postulés pour HEEG : <span className="">{statEcole['HEEG'] ? statEcole['HEEG'] : 0}</span> </h6>
            </div>
            <div className="p-3 mt-2 bg-info bg-opacity-10 border border-success border-start-0 rounded-end">
                    <h6>Candidats postulés pour ES2A : <span className="">{statEcole['ES2A'] ? statEcole['ES2A'] : 0}</span> </h6>
            </div> */}
            <div className="p-3 mt-2 bg-info bg-opacity-10 border border-success border-start-0 rounded-end">
                    <h6>Teachers : <span className="">{statEcole['teacher'] ? statEcole['teacher'] : 0}</span> </h6>
            </div>
            <div className="p-3 mt-2 bg-info bg-opacity-10 border border-success border-start-0 rounded-end">
                    <h6>Students: <span className="">{statEcole['student'] ? statEcole['student'] : 0}</span> </h6>
            </div>
                {/* <div id="diagrammes" className="d-flex flex-row flex-wrap">
                  Statistques par centre <br />
                    <div>
                        <p>Choix de l'école</p>
                        <Graphe data={[statEcole.ESTI,statEcole.HEEG ,statEcole.ES2A ,statEcole.ESU2A , statEcole.ESMGE]} labels={['ESTI', 'HEEG','ES2A',"ESU2A","ESMGE"]} />
                    </div>
                    <div>
                        <p>Civilité</p>
                        <Graphe data={[civilite.F, civilite.M]} labels={['Feminin', 'Masculin']} />
                    </div>
                </div> */}
        </div>
    )
}

export default DashboardHome;