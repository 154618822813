import {AuthProvider} from "./AuthContext";
import {CredsProvider} from "./CredsContext";
import {CandidatProvider} from "./CandidatContext";

const ContextWrapper = ({ children }) => {


    return (
        <AuthProvider>
            <CredsProvider>
                <CandidatProvider>
                    {children}
                </CandidatProvider>
            </CredsProvider>
        </AuthProvider>
    )
};
export default ContextWrapper;