import React from 'react'
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@mui/material/Alert';


const AlerteMessage = ({open , handleClose, message}) => {
    
        return (
          <>
      <Snackbar open={open} autoHideDuration={4000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleClose}>
              <MuiAlert onClose={handleClose} elevation={6} severity="error" sx={{ width: '100%' }}>
               {message}
              </MuiAlert>
            </Snackbar>
          </>
        )
      }

export default AlerteMessage