import React from "react";
import CandidatContext from "../../../context/CandidatContext";
import CandidatInfo from "../../../components/CandidatInfo/CandidatInfo";
import axios from "axios";
import { Table, Container, Button } from "react-bootstrap";
import BaseModal from "../../../components/BaseModal/BaseModal";
import './Candidat.css'

class Candidat extends React.Component {
    static contextType = CandidatContext;
  
    constructor(props) {
      super(props);
      this.state = {
        chunkSize: 10,
        fetchIndex: 0,
        totalItems: 0,
        candidatsToDisploy: [],
        selectedCandidat : {},
        modalTital : "Information Candidat",
        show: false,
        modalChild : (<h1>Nothing to display</h1>),
        completed : false,
        isOk:false,
      };
    }

    componentDidMount() {
      this.fetchData(0);
    }
  
    changeModelVisibility = (bool) => this.setState({show : bool})
    setModalChild = (child) => this.setState({modalChild : child})

    fetchData = async (index) => {
      const { candidatContext, setCandidatContext } = this.context;
      const { chunkSize, fetchIndex, totalItems } = this.state;
        try {

            const response = await axios.get(`https://depot.uam.sn/api/v1/vacation/chunck?fetch_index=${index}&chunck_size=${chunkSize}`); //for local test and usage
            console.log(response)
            if (response.status === 200) {
              const newCandidats = candidatContext?.candidats.slice();
              newCandidats.push(...response.data?.candidats);
              //setCandidatContext({ candidats: newCandidats });
              this.setState({ totalItems: response.data?.total_candidat });
              this.setState({ candidatsToDisploy: [...response.data?.candidats] });
              this.setState({ isOk : true});
              console.log(this.state.isOk)
            }
        } catch (error) {
          this.setState({ isOk : false});
          console.log(this.state.isOk)
        }
      
    };
  
    render() {
        const { candidatContext, setCandidatContext } = this.context;
        const { show, candidatsToDisploy, chunkSize, fetchIndex, totalItems, modalTital,isOk } = this.state;

       console.log("Candidats à afficher " + candidatsToDisploy)
        return (
          <div className="candidatPage p-2 mb-2" id="candidataMain">
            <p >Listes des candidat ayant déposés </p>
            <section>
              {isOk ? <>
                {(candidatsToDisploy?.length > 0) ?
         
         <Table striped bordered hover className="table align-middle mb-0">
             <thead className="bg-light">
                 <tr>
                     <th>Prenom </th>
                     <th>Nom</th>
                     <th>Serie</th>
                     <th>Moyenne BAC</th>
                     <th>Ecole</th>
                     <th>Action</th>
                 </tr>
             </thead>
             <tbody>
             {candidatsToDisploy.map((candidat) => (
                 <tr key={candidat.email}>
                     <td>
                         <span className=" ">{candidat.prenom}</span>
                     </td>
                     <td>
                         <span className=" ">{candidat.nom}</span>
                     </td>
                     <td>
                         <span className=" ">{candidat.serie}</span>
                     </td>
                     <td>
                         <span className=" ">{candidat.moyenne_bac}</span>
                     </td>
                     <td>
                         <span className=" ">{candidat.premier_choix_ecole}</span>
                     </td>
                     <td>
                         <button className="btn btn-outline-primary uam-primary"
                             style={{color : "#993938", borderColor: "#993938"}}
                             onClick={() =>{
                                this.setState({selectedCandidat : candidat})
                                this.setModalChild(<CandidatInfo candidat={candidat} />);
                                this.changeModelVisibility(true);
                             }} >
                             consulter
                         </button>
                     </td>
                 </tr>)
             )}
             </tbody>
             </Table>
                 :
                 <Container className="my-5 text-center">
                     <h4>{"Aucun candidat n'a encore été enregister pour cet campagne"}</h4>
                 </Container>
              
     }
              </> : 
              <Container className="my-5 text-center">
                       <h4>{"En cours de récupération"}</h4>
                </Container>
              }
        <BaseModal show={show} size={"md"} handleClose={() => this.changeModelVisibility(false)} title={modalTital} children={this.state.modalChild} >

        </BaseModal>

   </section>
   <div id="buttonsSlide">
   <button className="btn btn-light"
      style={{color : "#993938", borderColor: "#993938"}}
      disabled={fetchIndex === 0}
      onClick={() => {
        const index = fetchIndex - chunkSize

        
        if (index < 0)
            index = 0;
      //  console.log("before",index)
        this.setState({fetchIndex : index,})
        this.setState({completed : false})
        this.fetchData(index)
      }}
    >
      previous
    </button>
    <button className="btn btn-primary float-end"
      style={{color : "#993938", borderColor: "#993938"}}
      disabled={this.state.completed}

      onClick={() => {
        let index = fetchIndex + chunkSize

        if ((totalItems - index) < chunkSize){
            this.setState({completed : true})
        }
        this.setState({fetchIndex : index})
       // console.log("in  onclick",index)
        this.fetchData(index)
      }}
    >
      next
    </button>
   </div>
  </div>
        );
      }
    
  }

export default Candidat;