import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle} from '@fortawesome/free-solid-svg-icons';

const Recepisse = ({dataPerson,title, university}) => {

  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <Modal.Title>Candidature enregistrée</Modal.Title>
        <FontAwesomeIcon icon={faCheckCircle} className="green-icon m-2" size="lg" />
        </Modal.Header>
        <Modal.Body>
            <div className="container p-3 fs-5">
            <div className='mb-2'>
                  <div className='text-dark text-centered'>
                 Votre candidature a été enregistrée. 
                  </div>
                  <div>
                    Un mail de confirmation vous a été envoyé à  : <span className='fw-bold'>{dataPerson?.email}</span> 
                  </div>
                </div>
                {dataPerson.cycle ? 
                  <>
                      <div>
                          Prenom & Nom : <span className='text-uppercase'>{dataPerson.prenom +" " + dataPerson?.nom}</span>
                      </div>
                      <div>
                          Date & Lieu de Naissance : <span>{dataPerson.date_naissance + " à " +dataPerson?.lieu_naissance}</span>
                      </div>
                      <div> 
                      {title || 'Cycle'}  : <span>{dataPerson?.cycle}</span>
                      </div>
                      {/* <div>
                        {university|| 'Poste à pourvoir'} : <span>{dataPerson?.poste}</span>
                      </div> */}
                  </> 
                : null}
                
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Link to='/'>
              <Button variant="" onClick={handleClose}>
                Fermer
              </Button>
              </Link>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Recepisse