import React, { useState, useRef, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { Form, Image, Spinner } from "react-bootstrap";
import logo from '../../logo_uam.png'
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import Cookies from "js-cookie";
import './Connexion.css'
import { useNavigate } from 'react-router-dom';

const LOGIN_URL = 'https://depot.uam.sn/api/v1/user/token'   //pour l'utiliser en local (test)
function Connexion({}) {
    const userRef = useRef();
    const errRef = useRef();
    const {auth, setAuth} = useContext(AuthContext)
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSucces] = useState('');
    const navigator = useNavigate();
    const [submitted, setSubmitted] = useState(false)

    useEffect(()=> {
        console.log(userRef);
        //userRef.current.focus();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true)
        try {
            const response = await axios.post(
                LOGIN_URL,
                {
                    'username' : user,
                    'password' : pwd
                },
                {
                    headers: {"Content-Type": "multipart/form-data"},
                    withCredentials: true
                }
            );
            console.log(response.data);
            const accessToken = response?.data?.access_token
            const role = response?.data?.role
            setAuth({...auth , email : user, accessToken: accessToken});
            Cookies.set('accessToken', accessToken)
            setUser('');
            setPwd('');
            navigator("/dashboard")

        }catch(err){
            setSubmitted(false)
            if (!err?.response)
                setErrMsg("No serveur response");
            else if (err?.response.status === 400)
                setErrMsg("Missing Username or password");
            else
                setErrMsg("Login failed");
            errRef.current.focus();
            }
        }
        
    


  return (
    <>
       { success ? (
        <section>
            <h1>You are logged in</h1>
            <br />
            <p>
                <a href='Connexion#'>Go to Home</a>
            </p>
        </section>
       ) :
       (
        <>
        <div className="form2 container">
        <div className='' id='payHead'>
            <div>
            <p>BACK OFFICE <br />
            <Image src={logo} width={140} height={140} />
            </p>
            </div>
            {/* <div>
                <Image src={logo} width={100} height={100} />
            </div> */}
        </div>
       <Form onSubmit={handleSubmit} style={{display:"inline-block"}}>
            <p ref={errRef} className={errMsg ? "errMsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <div className="row" id="test">
            <Form.Group className="col-12" controlId="formBasicEmail">
                <Form.Label className=''><h5>Adresse email : </h5></Form.Label>
                <Form.Control
                        type="email"
                        name="email"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        placeholder="Enter email"
                        required
                    />
            </Form.Group>
            <Form.Group className="col-12" controlId="formBasicPassword">
                <Form.Label className=''><h5>Mot de passe : </h5></Form.Label>
                    <Form.Control
                            type="password"
                            name="password"
                            value={pwd}
                            onChange={(e) => setPwd(e.target.value)}
                            placeholder="************"
                            required  
                        />
            </Form.Group>
            </div>
            <div>
            <Button
                variant="success"
                className="btn-xl float-end"
                type="submit"
                >
                connexion {submitted ? <Spinner animation="grow" size="sm" role="status" /> : null}
          </Button>
            </div>
            
        </Form>
        </div>
        </>
        )
        }
    </>
  );
}

export default Connexion;