import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './CandidatInfo.css';

function CandidatInfo({candidat}) {



  return (
    <>
      <table className="candidate-table">
        <tbody>
          <tr>
              <th>Prénom et Nom </th>
              <td>:</td>
              <td>{candidat.prenom + " " + candidat.nom}</td>
          </tr>
          <tr>
              <th>Email :</th>
              <td>:</td>
              <td>{candidat.email}</td>
          </tr>
          <tr>
              <th>Choix 1</th>
              <td>:</td>
              <td>{candidat.premier_choix_ecole}</td>
          </tr>
          {(candidat.second_choix_ecole === 'ESTI' || candidat.second_choix_ecole ==='ESMGE' || candidat.second_choix_ecole ==='ESU2A' || candidat.troisieme_choix_ecole ==='ES2A' || candidat.troisieme_choix_ecole ==='HEEG') ? <tr>
              <th>Choix 2</th>
              <td>:</td>
              <td>{candidat.second_choix_ecole}</td>
          </tr> : <></>}
          {(candidat.troisieme_choix_ecole === 'ESTI' || candidat.troisieme_choix_ecole ==='ESMGE' || candidat.troisieme_choix_ecole ==='ESU2A' || candidat.troisieme_choix_ecole ==='ES2A' || candidat.troisieme_choix_ecole ==='HEEG') ? <tr>
              <th>Choix 3</th>
              <td>:</td>
              <td>{candidat.troisieme_choix_ecole}</td>
          </tr> : <></>}
          <tr>
              <th>Numero de télèphone</th>
              <td>:</td>
              <td>{candidat.telephone}</td>
          </tr>
          <tr>
              <th>Centre d'Examen:</th>
              <td>:</td>
              <td>{candidat.centre_examen}</td>
          </tr>
          <tr>
              <th>Numéro Transaction:</th>
              <td>:</td>
              <td>{candidat.paiement.num_transaction}</td>
          </tr>
          <tr>
              <th>Relevé de note BAC </th>
              <td>:</td>
              <td>
                <button className="btn btn-outline-primary uam-primary"
                    style={{color : "#993938", borderColor: "#993938"}}
                    onClick={() =>{
                        window.open(`/api/v1/candidat/view/${candidat.releve_bac}`);
                    }} >
                    consulter
                </button>
              </td>
          </tr>
          <tr>
              <th>Copie de la carte d'identité :</th>
              <td>:</td>
              <td>
                <button className="btn btn-outline-primary uam-primary"
                    style={{color : "#993938", borderColor: "#993938"}}
                    onClick={() =>{
                        window.open(`/api/v1/candidat/view/${candidat.copie_carte_id}`, '_blank');
                    }} >
                    consulter
                </button>
              </td>
          </tr>
          <tr>
              <th>Bulletin premier semetre Terminal:</th>
              <td>:</td>
              <td>
                <button className="btn btn-outline-primary uam-primary"
                    style={{color : "#993938", borderColor: "#993938"}}
                    onClick={() =>{
                        window.open(`/api/v1/candidat/view/${candidat.bulletin_terminal}`, '_blank');
                    }} >
                    consulter
                </button>
              </td>
          </tr>
          <tr>
              <th>Bulletin annuelle Premiere :</th>
              <td>:</td>
              <td>
                <button className="btn btn-outline-primary uam-primary"
                    style={{color : "#993938", borderColor: "#993938"}}
                    onClick={() =>{
                        window.open(`/api/v1/candidat/view/${candidat.bulletin_premiere}`, '_blank');
                    }} >
                    consulter
                </button>
              </td>
          </tr>
          <tr>
              <th>Bulletin annuelle Seconde </th>
              <td>:</td>
              <td>
                <button className="btn btn-outline-primary uam-primary"
                    style={{color : "#993938", borderColor: "#993938"}}
                    onClick={() =>{
                        window.open(`/api/v1/candidat/view/${candidat.bulletin_seconde}`, '_blank');
                    }} >
                    consulter
                </button>
              </td>
          </tr>
          </tbody>
      </table>
    </>
  );
}

export default CandidatInfo;