import Navbar from "../../components/Navbar/Navbar";
import { Routes, Route } from 'react-router-dom';
import Protected from "../../components/Protected/Protected"
import Candidat from "./CandidatPage/Candidat";
import DashboardHome from "./DashboardHome/DashboardHome";
import './Dashboard.css'

function DashBoard(){
    return <>
    <div id="body">
    <Navbar></Navbar>
        <Routes>
            <Route path="/"  element={<Protected children={<DashboardHome></DashboardHome>}></Protected>} />
            <Route path="/candidat" element={<Protected children={<Candidat></Candidat>}></Protected>} />
            <Route path="*" element={<h1>not found</h1>} />
        </Routes>
    </div>
    </>;
}


export default DashBoard;