import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function BaseModal({show, handleClose, children, title, size}) {

  

  return (
    <>
      <Modal show={show} onHide={handleClose} size={(size) ? size : ""}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           {children}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" style={{color : "#993938", borderColor: "#993938"}} onClick={handleClose}>
            fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BaseModal;