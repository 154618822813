import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './index.css';
import HomeConcours from './pages/HomeConcours';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import Pay from './pages/pay/Pay'
import Recrutement from './pages/Recrutement';
import ErrorPage from './components/Error';
import Recepisse from './components/RecepisseDepot';
import Connexion from "./pages/Connexion/Connexion";
import ContextWrapper from "./context/ContextWrapper";
import DashBoard from './pages/Dashbord/Dashboard';
import Concours from './pages/Concours';
import Home from './pages/Home';
import Update from './pages/Update';
import Nav from './components/Nav';
import Voyage from './pages/Voyage'
import Vacation from './pages/Vacation';
import Inscription from './pages/Inscription';
import HomeIns from './pages/HomeInscription';
import UpdateInscription from './pages/UpdateInscription';
import Erasmus from './pages/Erasmus';
import PayAdmission from './pages/PayAdmission/Pay';
import PayUniforme from './pages/PayUniforme/Pay';
import Admission from './pages/Admission';
import Restaurant from './pages/Restaurant/Restaurant';
import Inscription2 from './pages/Reinscription';
import Vote from './pages/Vote'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
    {/* <Home /> */}

    <ContextWrapper>
        <Router>
            <Nav />
            <Routes>
                <Route path="/" element = {<Pay />} />
                <Route path="/concours" element = {<HomeConcours />} />
                <Route path="/paiement" element = {<Pay />} />
                <Route path="/id" element = {<Recepisse />} />
                <Route path="/commande" element = {<Restaurant />} />
                <Route path="/depot" element = {<Concours />} />
                <Route path="/pay" element = {<PayAdmission />} />
                <Route path="/payment" element = {<PayUniforme />} />
                {/* <Route path="/recrutement" element = {<Recrutement />} /> */}
                <Route path="/connexion" element = {<Connexion />} />
                {/* <Route path="/update" element = {<Update />} /> */}
                {/* <Route path="/voyage" element = {<Voyage />} /> */}
                {/* <Route path="/vote" element = {<Vote />} />
                <Route path="/erasmus" element = {<Erasmus />} />
                <Route path="/vacation" element = {<Vacation />} />
                <Route path="/admission" element = {<Admission />} />
                <Route path="/inscription" element = {<Inscription />} />
                <Route path="/reinscription" element = {<Inscription2 />} />
                <Route path="/homeInscription" element = {<HomeIns />} />
                <Route path="/updateInscription" element = {<UpdateInscription />} /> */}
                <Route path="/dashboard/*" element = { <DashBoard />} />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </Router>
    </ContextWrapper>


    </React.StrictMode>
);
reportWebVitals();
