export default function ErrorPage() {

  return (
    <div id="error-page" className="container  m-5">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>404 Not Found</i>
      </p>
    </div>
  );
}