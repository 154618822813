import { createContext, useState } from "react";

const CredsContext = createContext({});

export const CredsProvider =  ({children}) => {
    const [creds, setCreds] = useState({
        num_transaction: null,
        email : null
    });
    return (
        <CredsContext.Provider value={{creds, setCreds}}>
            {children}
        </CredsContext.Provider>
    )
}

export default CredsContext;