import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


const chartContainerStyle = {
    width: '400px', // Réduire la largeur du diagramme
    height: '400px',
    flexWrap: 'wrap',
    display: 'flex',
    border:'3px solid yellow',
    gap: '20px', // Définissez la hauteur souhaitée du diagramme
  };
  const chartStyle = {
    width: '200px', // Réduire la largeur du diagramme
    height: '200px', // Réduire la hauteur du diagramme
  };
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

function Graphe({data, labels}) {
    const datas = {
//   labels: ['ESTI', 'HEEG', 'ES2A', 'ESMGE', 'ESU2A'],
    labels: labels,
    datasets: [
    {
      label: '# des candidats',
      data: data,
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
      ],
      borderWidth: 1,
    },
  ],
};
  return <div style={chartContainerStyle} className='border border-success' id='graph'>
        <Pie data={datas} options={chartOptions} style={chartStyle} />
  </div>
   
}
export default Graphe ;
