import React from 'react'
import ProgressBar from '../ProgressBar';
import logo from '../../img/logo EAA admin-ajax-1.png'
import slogan from '../../img/SLOGAN.png'
import { Image } from 'react-bootstrap';
import './nav.css'
const Nav = () => {
  return (
    <div>
        <div id='header'className='p-2'>
            <div className='' id='img-nav'>
                <a href="/"><Image src={logo} fluid  /></a>
                <Image src={slogan} fluid  />
            </div>
           
        </div>
    </div>
  )
}

export default Nav