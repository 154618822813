import React, { useState, } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Pay.css';
import logo from '../../img/logo_EAA.png'
import wave from '../../img/wave.jpg'
import om from '../../img/OM.jpg'
import axios from 'axios';
import freemoney from '../../img/free-money.jpg'
import expresso from '../../img/expresso.jpg'
import bank from'../../img/carte-bancaire-50px.svg';
import {Link} from 'react-router-dom';
import { Image, Form,Container,Button,Spinner } from 'react-bootstrap';
import IdTransaction from '../../components/idTransaction/IdTransaction';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import AlerteMessage from '../../components/AlerteMessage';
import { useEffect } from 'react';
import Input from '../../components/Input';
import InputT from '../../components/Input';
import { InputF } from '../../components/Input';
import guide from '../../docs/Notice_inscription_concours_POLYTECH 2024.pdf'
import brochure from '../../docs/brochure.pdf'
import RecepisseConcours from '../../components/Recepisse';
import UpdateDepot from '../../components/UpdateDepot';
import moment from 'moment';
    const bacSeries = ['S1', 'STEG', 'L2','L\'1','S1A','S2','S2A','S3','S4','S5','T1','L1a','F6','T2'];
const departments = {
    'scientifique': ['ST2AN', 'DSTI', 'DGAE',],
    'litteraire': ['DGO', 'DU2ADT'],
};
const filieres = {
    'S1': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DGO': ['Management des Organisations', 'Finance Comptabilité'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'S1A': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DGO': ['Management des Organisations', 'Finance Comptabilité'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'S2': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DGO': ['Management des Organisations', 'Finance Comptabilité'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'S2A': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DGO': ['Management des Organisations', 'Finance Comptabilité'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'S3': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DGO': ['Management des Organisations', 'Finance Comptabilité'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'S4': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'S5': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'T1': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
    },
    'T2': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
    },
    'L2': {
        'DGO': ['Management des Organisations', 'Finance Comptabilité'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'STEG': {
        'DGO': ['Management des Organisations', 'Finance Comptabilité'],
    },
    'L\'1': {
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'L1a': {
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'F6': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
};

function Pay ({prix}) {
    const [bacSerie, setBacSerie] = useState('');
    const [choices, setChoices] = useState({});
    const [totalChoices, setTotalChoices] = useState(0);
    const [firstChoiceType, setFirstChoiceType] = useState('');
    const [data, setData] = useState('');
    const handleSerieChange = (e) => {
        data.premier_choix_ecole = '';
        data.second_choix_ecole = '';
        data.troisieme_choix_ecole = '';
        data.quatrieme_choix_ecole = '';
        setBacSerie(e.target.value);
        setData({ ...data, 'serie': e.target.value});
    setChoices({});
    setOrderedChoices([]);
    setTotalChoices(0);
    setFirstChoiceType('');
    };
var choix_par_ordre = ['premier_choix_ecole', 'second_choix_ecole', 'troisieme_choix_ecole','quatrieme_choix_ecole'];
const [orderedChoices, setOrderedChoices] = useState([]);

const handleFiliereChange = (department, filiere, deptType) => {
    setChoices((prevChoices) => {
        const deptChoices = prevChoices[department] || [];
        let newChoices;
        let newTotalChoices = totalChoices;

        if (deptChoices.includes(filiere)) {
            // Supprimer le choix
            const newDeptChoices = deptChoices.filter((f) => f !== filiere);
            newTotalChoices -= 1;
            newChoices = { ...prevChoices, [department]: newDeptChoices };

            // Mise à jour de l'ordre des choix
            const updatedOrderedChoices = orderedChoices.filter(choice => !(choice.department === department && choice.filiere === filiere));
            setOrderedChoices(updatedOrderedChoices);

            // Mise à jour de l'état des données
            const updatedData = { ...data };
            choix_par_ordre.forEach((key, index) => {
                console.log(key,index)
                updatedData[key] = updatedOrderedChoices[index]?.filiere || '';
            });
            setData(updatedData);
        } else {
            if (newTotalChoices < 4 && deptChoices.length < 2) {
                if (newTotalChoices === 0) {
                    setFirstChoiceType(deptType);
                } else if (firstChoiceType && firstChoiceType !== deptType) {
                    return prevChoices;
                }
                const newDeptChoices = [...deptChoices, filiere];
                newTotalChoices += 1;
                newChoices = { ...prevChoices, [department]: newDeptChoices };

                // Ajouter le nouveau choix à la fin de l'ordre des choix
                const updatedOrderedChoices = [...orderedChoices, { department, filiere }];
                setOrderedChoices(updatedOrderedChoices);

                // Mise à jour de l'état des données
                const updatedData = { ...data };
                choix_par_ordre.forEach((key, index) => {
                    updatedData[key] = updatedOrderedChoices[index]?.filiere || '';
                });
                setData(updatedData);
            } else {
                return prevChoices;
            }
        }
        setTotalChoices(newTotalChoices);
        return newChoices;
    });
};

const renderDepartments = (deptType) => {
    return departments[deptType].map((department) => (
        <div key={department}>
            {/* <span className='fw-medium'>{department}</span> */}
            {department ==='ST2AN' ? <span className='fw-medium'>Sciences et Techniques Agricoles, Alimentaires et Nutritionnelles</span> : null }
            {department ==='DSTI' ? <span className='fw-medium'>Sciences et techniques de l'ingénieur</span> : null }
            {department ==='DGAE' ? <span className='fw-medium'>Géosciences Appliquées et Environnement</span> : null }
            {department ==='DU2ADT' ? <span className='fw-medium'>Urbanisme Architecture et Aménagement Durable des Territoires</span> : null }
            {department ==='DGO' ? <span className='fw-medium'>Gestion des Organisations</span> : null }
            {filieres[bacSerie][department] && filieres[bacSerie][department].map((filiere) => (
                <div key={filiere} className='form-check'>
                    <input
                        type="checkbox"
                        className='form-check-input'
                        id={`${department}-${filiere}`}
                        value={filiere}
                        checked={choices[department]?.includes(filiere) || false}
                        onChange={() => handleFiliereChange(department, filiere, deptType)}
                        disabled={
                            (!choices[department]?.includes(filiere) && totalChoices >= 4) ||
                            (firstChoiceType && firstChoiceType !== deptType) || (!choices[department]?.includes(filiere) && totalChoices >= 2 && deptType === 'litteraire')
                        }
                    />
                    <label className='form-check-label' htmlFor={`${department}-${filiere}`}>{filiere}</label>
                </div>
            ))}
        </div>
    ));
};
    const { search } = useLocation();
    const [showPay, setShowPay] = useState(false);
    const maxFileSize = 1024 * 1024;
    const [complete, setComplete] = useState(false);
    const [requiredOK, setRequiredOk] = useState(true);
    const [selectedFiles, setSelectedFiles] = useState({
        "photo":true,
        "extrait_naissance" : true,
        "autorisation" : true,
        "copie_diplome_licence" : true,
        "certificat_nationnalite" : true,
        "certificat_medical" : true,
        "certificat_nationnalite2" : true,
        "casier" : true,
    });
    const [gestionfichier, setGestionFichier] = useState({  
        "certificat_medical" : false,
        "certificat_nationnalite2" : false,
        "casier" : false
    });
    const [nationalities, setNationalities] = useState([]);
    const [controllerChoice, setControllerChoice] = useState(false)
    const [errors, setErrors] = useState(false);
    const [step, setStep] = useState(1);
    const [nmbrechoix, setNombreChoix] = useState(false)
    const[ messageAlerte, setMessageAlerte] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [showMatricule, setShowMatricule] = useState(false);
    const [showMessageEmail, setShowMessageEmail] = useState(false);
    const [show, setShow] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [checked, SetChecked] = useState(false);
    const [open, setOpen] = useState (false);
    const [message, setMessage] = useState (false);
    const [valid, SetIsValid] = useState({
        isValidEmail: false,
        isValidPhoneNumber: false,
        isValidMatricule : false,
      });
      const [older,setOlder] = useState(false);
      const [showUpdate, setShowUpdate] = useState(false);
      const handleCloseUpdate = () => {
        setShowUpdate(false);
      }
    const handleChange = (e) => {
        const name= e.target.name;
        let value = e.target.value;
        if (name === 'telephone') {
            const phoneRegex = /^(70|75|76|77|78)\d{7}$/;
            const isValidPhoneNumber = phoneRegex.test(value);
        
            SetIsValid((valid) => ({
                ...valid,
                isValidPhoneNumber: isValidPhoneNumber,
            }));
            setShowMessage(true);
        }
        if (name === 'matricule') {
            const matriculeRegex = /^\d{9}$/;
            const isValidMatricule = matriculeRegex.test(value);
        
            SetIsValid((valid) => ({
                ...valid,
                isValidMatricule: isValidMatricule,
            }));
            setShowMatricule(true);
        }
        if (name==="nom") {
            let nom  = value.toUpperCase()
            value = nom
            // console.log(nom)
        }
        if (name==="date_naissance") {
            const referenceDate = moment('2025-12-31');
            const dateNaissance = moment(value); // Remplacez cela par la date de naissance réelle
            const ageCalcul = referenceDate.diff(dateNaissance, 'years')
          const maxAge = 25;
          console.log(ageCalcul)
          if (ageCalcul > maxAge || ageCalcul < 20) {
            console.log(ageCalcul)
            setOlder(true)
            setData({ ...data, [name]: value });
            console.log(data);
            setPhoto(true);
            setOpen(true);
            setMessage("Avec cette de date de naissance vous n'êtes pas éligible");
            return
          } else {
            setOlder(false)
            setPhoto(false)
            console.log(data)
          }
        }
        if (name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            SetIsValid((valid) => ({
                ...valid,
                isValidEmail: emailRegex.test(value),
            }));
            setShowMessageEmail(true)
        }
        if (name === 'operateur') {
            SetChecked(e.target.checked)
        }
        setData({ ...data, [name]: value })
    }
    const handleClose3 = () => {
        setOpen(false)
    }
    const [photo, setPhoto] = useState(false);
    const handleFileChange = (event, key) => {
        const elements = document.getElementsByName(key)
        // let file = event.target.files[0];
        // console.log(key)
        // if (file && file.type !== 'application/pdf') {
        //     setSelectedFiles((prevSelectedFiles) => ({
        //         ...prevSelectedFiles,
        //         [key]: false
        //     }));
        //     setGestionFichier((prevSelectedFiles) => ({
        //         ...prevSelectedFiles,
        //         [key]: false
        //     }));
        //     setPhoto(true);
        //     setOpen(true)
        //     setMessage("Seul le format pdf est autorisé !")
        //     event.target.value = '';
        //     return ;
        // }
        // const elements = document.getElementsByName(key);
        let file = event.target.files[0];
        console.log(key);
        if (key === 'photo') {
            // Autoriser uniquement les images PNG et JPG pour le key 'photo'
            if (file && !(file.type === 'image/jpeg')) {
                setSelectedFiles((prevSelectedFiles) => ({
                    ...prevSelectedFiles,
                    [key]: false
                }));
                setGestionFichier((prevSelectedFiles) => ({
                    ...prevSelectedFiles,
                    [key]: false
                }));
                setPhoto(true);
                setOpen(true);
                setMessage("Seuls le format JPG est autorisé pour la photo !");
                event.target.value = '';
                return;
            }
        } else {
            // Autoriser uniquement les PDF pour les autres keys
            if (file && file.type !== 'application/pdf') {
                setSelectedFiles((prevSelectedFiles) => ({
                    ...prevSelectedFiles,
                    [key]: false
                }));
                setGestionFichier((prevSelectedFiles) => ({
                    ...prevSelectedFiles,
                    [key]: false
                }));
                setPhoto(true);
                setOpen(true);
                setMessage("Seul le format PDF est autorisé !");
                event.target.value = '';
                return;
            }
        }
        if (file?.size < maxFileSize){
            // var type = file.type ='application/pdf' : 'application/pdf' ? 'image/jpeg'
            // if (key !== 'photo') {
            var filesinfos ;
                if (file.type ==='application/pdf') {
                    if (!data.nom || !data.prenom) {
                    setOpen(true)
                    setMessage("Veuiller renseigner d'abord le nom et prénom !");
                    event.target.value = '';
                    return
                    }
                    data.nom = data.nom.trim()
                    data.prenom = data.prenom.trim()
                    console.log(data.prenom,data.nom)
                    // var filesinfos = file.slice(0, file.size, 'image/jpeg'); 
                    // file = new File([filesinfos], `${infos.codePermanent}.jpeg`, {type: 'image/jpeg'});
                    filesinfos = file.slice(0, file.size, 'application/pdf'); 
                    file = new File([filesinfos], `${key+"_"+data.prenom+"_"+data.nom}.pdf`, {type: 'application/pdf'});
                    console.log(file)
                    setPhoto(false)
                    setSelectedFiles((prevSelectedFiles) => ({
                        ...prevSelectedFiles,
                        [key]: file
                    }));
                    setGestionFichier((prevSelectedFiles) => ({
                        ...prevSelectedFiles,
                        [key]: true
                    }));
                    for (var i = 0; i < elements.length; i++) {
                        var element1 = elements[i];
                        element1.style.border = "";
                    }
                } else {
                    if (!data.nom || !data.prenom) {
                        setOpen(true)
                        setMessage("Veuiller renseigner d'abord le nom et prénom !");
                        event.target.value = '';
                        return
                        }
                        data.nom = data.nom.trim()
                        data.prenom = data.prenom.trim()
                        // var filesinfos = file.slice(0, file.size, 'image/jpeg'); 
                        // file = new File([filesinfos], `${infos.codePermanent}.jpeg`, {type: 'image/jpeg'});
                        filesinfos = file.slice(0, file.size, 'image/jpeg'); 
                        file = new File([filesinfos], `${key+"_"+data.prenom+"_"+data.nom}.jpeg`, {type: 'image/jpeg'});
                        console.log(file)
                        setPhoto(false)
                        setSelectedFiles((prevSelectedFiles) => ({
                            ...prevSelectedFiles,
                            [key]: file
                        }));
                        setGestionFichier((prevSelectedFiles) => ({
                            ...prevSelectedFiles,
                            [key]: true
                        }));
                        for (i = 0; i < elements.length; i++) {
                            element1 = elements[i];
                            element1.style.border = "";
                        }
                    // setPhoto(true)
                    // setOpen(true)
                    // setMessage("format de fichier non permis")
                    // // console.log("format de fichier non permis ")
                    // setGestionFichier((prevSelectedFiles) => ({
                    //     ...prevSelectedFiles,
                    //     [key]: false
                    // }));
                    // return
                }   
            // } else {
            //     // console.log(file)
            //     setSelectedFiles((prevSelectedFiles) => ({
            //         ...prevSelectedFiles,
            //         [key]: file
            //     }));
            //     setGestionFichier((prevSelectedFiles) => ({
            //         ...prevSelectedFiles,
            //         [key]: true
            //     }));
            //     for (var J = 0; J < elements.length; J++) {
            //         var element = elements[J];
            //         element.style.border = ""; // Applique une bordure rouge
            //     }
            // }
        } else {
            // console.log('fichier top gros !')
            setSelectedFiles((prevSelectedFiles) => ({
                ...prevSelectedFiles,
                [key]: false
            }));
            setGestionFichier((prevSelectedFiles) => ({
                ...prevSelectedFiles,
                [key]: false
            }));
            for (var j = 0; j < elements.length; j++) {
                var element2 = elements[j];
                element2.style.border = "2px solid #BC0C0C";
                element2.style.transition ='border 0.5s ease-in-out;' // Applique une bordure roug
            }
            setSelectedFiles((prevSelectedFiles) => ({
                ...prevSelectedFiles,
                [key]: false
            }));
            setGestionFichier((prevSelectedFiles) => ({
                ...prevSelectedFiles,
                [key]: false
            }));
            setPhoto(true);
            setOpen(true)
            setMessage("Le fichier chargé est supérieur à 1 Mo")
            event.target.value = '';
            return ;
        }
    };
    let NotComplete = true;
    if (!valid.isValidEmail || !valid.isValidPhoneNumber || !checked || older) {
        NotComplete = false;
    }
    const calculateProgress = () => {
        return (step / 2) * 100;
      };
      const handlePreviousStep = () => {
        setStep(step - 1);
      };
      const handleNextStep = () => {
        if (!valid.isValidEmail || !valid.isValidPhoneNumber || !checked) {
            setErrors(true);
            if (!valid.isValidPhoneNumber) {
                setShowMessage(true);
            }
            if (!valid.isValidEmail) {
                setShowMessageEmail(true)
            }
        } else {
            setErrors(false);
            setStep(step + 1);
        }
    };
    const [succes,setSucces] = useState(false);
    const [depotsuccess, setDeppotSuccess] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        // if (older) {
        //     setPhoto(true);
        //     setOpen(true);
        //     setMessage("Avec cette de date de naissance vous n'êtes pas éligible");
        // }
        // console.log(firstChoiceType);
        // console.log(choices)
        const match = search.match(/email=(.*)/);
        const emailFromURL = match?.[1];
        // console.log(emailFromURL)
        if (emailFromURL){
            axios({
                url:'/api/v1/paiement/confirmed/',
                method: 'POST',
                data:{
                    'email':emailFromURL
                }
            })
            .then(result => {
                // console.log(result.data)
                // alert(result.data.campagne)
                // setData(result.data)
                if (result.data.confirmed_depot === 0) {
                    setDeppotSuccess(true)
                    setSucces(result.data)
                    return
                }
                else if (result.data.campagne==='reinscription' || result.data.campagne==='master') {
                    navigate(`/`);
                }
                 else {
                    navigate('/')
                }
            })
            .catch((error) => {
                console.error(error)
            });
      }
    //   console.log(data)
        // if (data.serie === 'L2' && (getDepartementByFiliere(data.premier_choix_ecole) ==='DGO' || getDepartementByFiliere(data.second_choix_ecole) ==='DGO') && data.moyenne_terminal < 12) {
        //     setPhoto(true);
        //     setOpen(true);
        //     setMessage('Une moyenne de 12 est requis en terminale!')
        // } else {
        //     setOpen(false)
        //     setPhoto(false)
        // }
        // if (firstChoiceType==='litteraire') {
        //     if ((!data.premier_choix_ecole || !data.second_choix_ecole) && (bacSerie==='L2')) {
        //         if (data.moyenne_terminal < 12) {
        //             setNombreChoix(true)
        //         } else {
        //             setNombreChoix(true)
        //         }
        //     } else {
        //         setNombreChoix(false)
        //     }
        //     if (getDepartementByFiliere(data.premier_choix_ecole) ==='DGO' && getDepartementByFiliere(data.second_choix_ecole)==='DGO' ) {
        //         // console.log('impossible de le faire ')
        //         // setControllerChoice(true)
        //     }
        //     else if ((getDepartementByFiliere(data.premier_choix_ecole) ==='DU2ADT' && getDepartementByFiliere(data.second_choix_ecole)==='DU2ADT')) {
        //         console.log('impossible de le faire ')
        //         // setControllerChoice(true)
        //     } else {
        //         setControllerChoice(false)
        //     }
        // }
        // function getDepartementByFiliere(filiere) {
        //     for (const niveau in filieres) {
        //         for (const departement in filieres[niveau]) {
        //             if (filieres[niveau][departement].includes(filiere)) {
        //                 return departement;
        //             }
        //         }
        //     }
        //     return null; // Retourne null si la filière n'est pas trouvée
        // }
        // const departement1 = getDepartementByFiliere(data.premier_choix_ecole) ===null ? '' :  getDepartementByFiliere(data.premier_choix_ecole);
        // const departement2 = getDepartementByFiliere(data.second_choix_ecole) ===null ? '' :  getDepartementByFiliere(data.second_choix_ecole);
        // const departement3 = getDepartementByFiliere(data.troisieme_choix_ecole) ===null ? '' :  getDepartementByFiliere(data.troisieme_choix_ecole);
        // const departement4 = getDepartementByFiliere(data.quatrieme_choix_ecole) ===null ? '' :  getDepartementByFiliere(data.quatrieme_choix_ecole);
        // const controller = []
        // if (departement1 && departement2 && departement3 && departement4) {
        //     controller.push(departement1,departement2,departement3,departement4);
        // }
        // function countOccurrences(element) {
        //     let count = 0;
        //     for (let i = 0; i < controller.length; i++) {
        //         if (controller[i] === element) {
        //             count++;
        //         }
        //         // console.log(count)
        //     }
        //     if( count === 2 ) {
        //         return count;
        //     } else {
        //         setControllerChoice(false);
        //     }
        // }
        // console.log(controller)
        // if (controller.length===4) {
        //     if (countOccurrences(departement1) === countOccurrences(departement2) || countOccurrences(departement1) === countOccurrences(departement3) || countOccurrences(departement1) === countOccurrences(departement4) ||  countOccurrences(departement2) === countOccurrences(departement4)  ||  countOccurrences(departement3) === countOccurrences(departement2)) {
        //         if (countOccurrences(departement1)===2 && countOccurrences(departement2) ===2 && countOccurrences(departement3) && countOccurrences(departement4)) {
        //             // console.log("choix pas possible " +departement1,departement2,departement3,departement4)
        //             setControllerChoice(true)
        //         } else {
        //             // console.log("choix possible ")
        //             setControllerChoice(false)
        //         }
        //     } else {
        //         // console.log("choix bien possible")
        //         setControllerChoice(false)
        //     }
        //     // alert('choix de deux départements impossible ')
        // }
        if (showMessage) {
            const timer = setTimeout(() => {
                setShowMessage(false);
            }, 1000); // 2 seconds
            return () => clearTimeout(timer);
        }
        if (showMessageEmail) {
            const timer = setTimeout(() => {
                setShowMessageEmail(false);
            }, 1000); // 2 seconds
            return () => clearTimeout(timer);
        }
        if (showMatricule) {
            const timer = setTimeout(() => {
                setShowMatricule(false);
            }, 1000); // 2 seconds
            return () => clearTimeout(timer);
        }
        const fetchNationalities = async () => {
            try {
              const response = await fetch('https://restcountries.com/v3.1/all');
              if (!response.ok) {
                throw new Error('Unable to fetch nationalities.');
              }
              const data = await response.json();
              let nationalities = data.map(country => country.name.common);
              nationalities = nationalities.sort()
              setNationalities(nationalities)
              return nationalities;
            } catch (error) {
              console.error('Error fetching nationalities:', error);
              return [];
            }
          };
          fetchNationalities()
    }, [showMessage,setShowMatricule,showMatricule, showMessageEmail, choices,data.premier_choix_ecole,data.second_choix_ecole,data.troisieme_choix_ecole,data.quatrieme_choix_ecole,controllerChoice,data,firstChoiceType,data.moyenne_terminal,navigate,search,showPay,bacSerie]);
    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const formData = new FormData();
            data.nom = data.nom.trim()
            data.prenom = data.prenom.trim()
            if (data.type_concours ==='Officier') {
                formData.append('campagne','concours1')
            } else {
                formData.append('campagne','concours2')
            }
            const nameFiles = ["photo","extrait_naissance","copie_diplome_licence","certificat_nationnalite","casier","certificat_medical","autorisation"]
            for (const i in nameFiles) {
                if (selectedFiles[nameFiles[i]].size) {
                    // console.log('fichier bien rempli')
                    if ((selectedFiles[nameFiles[i]]).size <= maxFileSize) {
                        formData.append(nameFiles[i] , selectedFiles[nameFiles[i]])
                    } else {
                        // console.log("fichier trop gros");
                    }
                } else {
                    console.log(data.type_candidat, i)
                    if (data.type_candidat ==='militaire' && nameFiles[i]==='autorisation') {
                        setPhoto(true);
                        setOpen(true);
                        setMessage("le document "+ nameFiles[i]+ " est obligatoire !");
                        setErrors(true);
                        return
                    } else if (data.type_candidat ==='civil' && nameFiles[i]==='autorisation'){
                        console.log("pas obligatoire !")
                    } else {
                        console.log(" dans le else")
                        setPhoto(true);
                        setOpen(true);
                        setMessage("le document "+ nameFiles[i]+ " est obligatoire !");
                        setErrors(true);
                        return
                    }
                }
            }
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    if( data[key] !== null || data[key] !== '') {
                        // if (data[key] === '' || data[key] === null) {
                        //     if ((key ==='troisieme_choix_ecole' || key ==='quatrieme_choix_ecole') & (firstChoiceType==='litteraire'))  {
                        //         data.troisieme_choix_ecole = '';
                        //         data.quatrieme_choix_ecole = '';
                        //     } else {
                        //         setPhoto(true);
                        //         setOpen(true);
                        //         setMessage("le champs "+ key + " est obligatoire.");
                        //         setErrors(true);
                        //         return
                        //     }
                        // }
                        formData.append(key ,data[key])
                    } else {
                        // console.log(data[key])
                    }
                }
            }
             for (let pair of formData.entries()) {
                // console.log(pair[0] + ':' + pair[1]);
            } 
            const fieldsCount = Array.from(formData.entries()).length;
            // console.log("Champs remplis coorrectement "+fieldsCount)
            if (!valid.isValidPhoneNumber) {
                setMessageAlerte('Incorrect')
                setShow(true);
            }
            else if (!data.type_concours ) {
                setPhoto(true);
                setOpen(true);
                setMessage("les choix sont obligatooire");
            }
            // else if ((firstChoiceType === 'scientifique') & (data.premier_choix_ecole='' || data.second_choix_ecole==='' || data.troisieme_choix_ecole==='' || data.quatrieme_choix_ecole==='')) {
            //     setPhoto(true);
            //     setOpen(true);
            //     setMessage("les choix sont obligatooire");
            // }
            // else if ((firstChoiceType === 'litteraire') & (data.premier_choix_ecole='' || data.second_choix_ecole==='')) {
            //     setPhoto(true);
            //     setOpen(true);
            //     setMessage("les choix sont obligatooire");
            // }
            // else if (data.premier_choix_ecole==='true' || data.second_choix_ecole==='false' || data.premier_choix_ecole==='false' || data.second_choix_ecole==='true') {
            //     setPhoto(true);
            //     setOpen(true);
            //     setMessage("les choix sont obligatooire");
            // }
            else {
                setErrors(false)
                setComplete(true)
                setSubmitted(true);
                // console.log("Requete en cours d'envoi ...")
                const response = await axios.post(
                    '/api/v1/paiement/',
                    formData,
            {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                    });

            if (response.status === 200) {
                setSubmitted(false);
            // console.log("Initialisation Success");
            // console.log('Data from API :', response.data);
            if (response.data.message==='OK') {
                // console.log(response.data)
                setSubmitted(false);
                setShowPay(true);
                setStep(1);
                setData('');
                if (response.data.paymentURL) {
                    window.location.href = response.data.paymentURL;
                }
            }
            else if (response.data.detail) {
                // console.log(response.data.detail)
                    setSubmitted(false);
                    setOpen(true);
                    setMessage(response.data.detail)
            }
            } else {
                if (response.data.detail) {
                    // console.log(response.data.detail)
                    setSubmitted(false);
                    setOpen(true);
                    setMessage(response.data.detail)
                }
                // console.error('Error uploading files');
            }
        }
    } catch (error) {
        setSubmitted(false);
        setOpen(true)
        setMessage('Impossible de procéder à un paiement !')
    //    console.error('Error uploading files', error);
    }
};
    const handleUpdate = (e) => {
        setShowUpdate(true)
    }
    return (
        <>
        <UpdateDepot showUpdate = {showUpdate} handleCloseUpdate = {handleCloseUpdate} />
        <AlerteMessage open={open} handleClose={handleClose3} message={message} />
        <IdTransaction show={showPay} email={data.email} handleClose={() => 
            {setShowPay(false)}
            } />
        {depotsuccess ? <RecepisseConcours  dataPerson={succes}/> : null}
        <Container className='pay' id='mainConcours'>
            <div className='d-flex flex-row' id='payHead'>
                <div className='text-center' id='texthead'>
                    <h3 className='text-center'>Concours d'entrée à l'Ecole de l'Armée de l'Air Edition 2025</h3>
                    
                </div>
            </div>
            <div className='homeConcours'>
                <Form onSubmit={handleSubmit} className=''>
                    <div>
                    <div id="">
                            <p className='ml-2' id='' style={{color:'red', fontSize:'15px'}}>Toute tentative de fraude ou de falsification entraînera l'exclusion immédiate du concours</p>
                    </div>
                    {step === 1 && (
                        <div id='step1Cncours'>
                            <div className='p-4 row' id='headInput'>
                                {/* informations personnelles */}
                                <Form.Group className='col-md-6 col-sm-12'>
                                    <Form.Control 
                                        placeholder='Ex 777777777'
                                        size='md'
                                        type='number'
                                        name='telephone'
                                        className='p-2 custom-email-input'
                                        onChange={handleChange}
                                        value={data.telephone || ''}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className='col-md-6 col-sm-12'>
                                    <Form.Control 
                                        placeholder='Email'
                                        size='md'
                                        type='email'
                                        name='email'
                                        value={data.email || ''}
                                        className='p-2 custom-email-input'
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </div>
                            {showMessage && (
                <span className={`${valid.isValidPhoneNumber ? 'text-success' : 'text-danger'}`}>
                    {valid.isValidPhoneNumber ? 'Numéro valide' : 'Numéro incorrect'}
                </span>
            )}
                            {showMessageEmail && (
                <span className={`${valid.isValidEmail ? 'text-success' : 'text-danger'}`}>
                    {valid.isValidEmail ? 'Email valide' : 'Email incorrect'}
                </span>
            )}
                                {/* {!valid.isValidPhoneNumber ? <span ><i className='alert alert-success w-25 m-2' >Incorrect</i> </span> : <span className='alert alert-success' >Numéro valide</span>} */}
                                <div id="labelFrais">
                                    <p className='ml-2' id='frais' style={{color:'#fff', fontSize:'15px'}}>Le paiement des frais de dossier non remboursable est de  (5 000) F. CFA + frais de 100 F. CFA</p>
                                </div>
                            <Form.Group id='operateur'>
                                <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    checked={data.operateur==='wave'}
                                    name="operateur"
                                    value="wave"
                                    label = {
                                        <Image src={wave} height={80} width={80} alt="wave" />
                                    }
                                />
                                <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    checked={data.operateur==='orange-money'}
                                    name="operateur"
                                    value="orange-money"
                                    label = {
                                        <Image src={om} height={80} width={80} alt="orange-money" />
                                    }
                                />
                                <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    name="operateur"
                                    value="free-money"
                                    checked={data.operateur==='free-money'}
                                    label = {
                                        <Image src={freemoney} height={80} width={80} alt="free-money" />
                                    }
                                />
                                <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    name="operateur"
                                    checked={data.operateur==='e-money'}
                                    value="e-money"
                                    label = {
                                        <Image src={expresso} height={80} width={80} alt="e-money" />
                                    }
                                />
                            </Form.Group>
                            <div className="p-2">
                            
                            {/* <div className="col-lg-4 col-sm-12">
                                    <Button variant="warning" className='mb-2' size="md" onClick={handleUpdate}>
                                       <span style={{color:'#fff'}}>Modifier sa candidature</span> 
                                    </Button>
                            </div> */}
                            </div>
                            
                        </div>)}
                        
                </div>
                    {/* fin de step 1 */}
                    {step === 2 && (
                        <div id="step2Pay">
                            <Form.Group id='operateur' style={{color:'#FFF'}} className='d-inline mb-2'>
                            <Form.Label style={{color:'#FFD524'}} className='fw-medium'>Type Concours <span style={{color:'red'}}>*</span> :  </Form.Label>
                                <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    name="type_concours"
                                    value="Officier"
                                    checked={data.type_concours==='Officier'}
                                    label="Officier"
                                    // label = {
                                    //     <Image src={freemoney} height={80} width={80} alt="free-money" />
                                    // }
                                />
                                <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    name="type_concours"
                                    checked={data.type_concours==='Sous Officier'}
                                    value="Sous Officier"
                                    label="Sous Officier"
                                    // label = {
                                    //     <Image src={expresso} height={80} width={80} alt="e-money" />
                                    // }
                                />
                                <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    disabled
                                    checked={data.type_concours==='Test Psychotechnique'}
                                    name="type_concours"
                                    value="Test Psychotechnique"
                                    label="Test Psychotechnique"
                                    // label = {
                                    //     <Image src={om} height={80} width={80} alt="orange-money" />
                                    // }
                                />
                            </Form.Group>
                            <div className="head">
                                <span className='text-bold'>Informations personnellles</span> 
                            </div>
                           <div className='row infosPerso' id='test'>
                           <Form.Group>
                                {/* <Form.Select name='type_concours' required value={data.type_concours} onChange={handleChange}>
                                    <option value="">--- Choisir un type ---</option>
                                    <option>Concours Officier</option>
                                    <option disabled>Concours Sous Officier</option>
                                    <option disabled>Concours des Tests Psychotechnique</option>
                                </Form.Select> */}
                            </Form.Group>
                                <Form.Group>
                                    <div className='d-inline' style={{color:'#FFF'}}>
                                        <Form.Check
                                        inline
                                                type="radio"
                                                label="Monsieur"
                                                value="M"
                                                required
                                                checked={data.civilite==='M'}
                                                name="civilite"
                                                onChange={handleChange}
                                            />
                                    </div>
                                    <div className='d-inline' style={{color:'#FFF'}}>
                                            <Form.Check
                                            checked={data.civilite==='F'}
                                                inline
                                                type="radio"
                                                label="Madame"
                                                value="F"
                                                required
                                                name="civilite"
                                                onChange={handleChange}
                                            />
                                    </div>
                                </Form.Group>
                            <div>
                            </div>
                                <Input className="col-md-6 col-sm-12" value={data.prenom || ''}  type='text' label="Prénom (s)" name='prenom' onChange={handleChange} />
                                <Input className="col-md-6 col-sm-12" value={data.nom || ''}   type='text' label="Nom" name='nom' onChange={handleChange}/>
                                <Input type='date' className='col-md-6 col-sm-12' label="Date de Naissance (jour/mois/année)" name='date_naissance' value={data.date_naissance || ''} onChange={handleChange} />
                                {older ? <span style={{color:'#fff'}}>Le concours est ouvert aux candidats âgés de 20 ans au moins et de 25 au plus à la date du 31 Décembre 2025</span>: null}
                                <Input type='text' className='col-md-6 col-sm-12' label="Lieu de Naissance" name='lieu_naissance' value={data.lieu_naissance} onChange={handleChange} />
                                <Input type='text' className='col-md-6 col-sm-12' label="Adresse" name='address' value={data.address} onChange={handleChange} />
                                <Input type='text' className='col-md-6 col-sm-12'  label="Numéro d'Identification Nationnale (NIN)" name='nin' value={data.nin} onChange={handleChange} /> <br />
                            </div>
                            <div className="row">
                                
                            </div>
                            {/* informations comlémentaires d'étude */}
                            {/* <div className="head">
                                <span className='text-bold' >Informations Complémentaires</span> 
                            </div> */}
                                <div className="head">
                                <span className='text-bold'>Informations du concours</span> 
                            </div>
                            <div >
                            <Form.Group id='serie'>
                                <Form.Label style={{color:'#FFD524'}} className='fw-medium'>Type Candidat<span style={{color:'red'}}>*</span></Form.Label>
                                <Form.Select name='type_candidat' required value={data.type_candidat} onChange={handleChange}>
                                    <option value="">---Veuiller choisir un type ---</option>
                                    <option value='civil'>Civil</option>
                                    <option value='militaire'>Militaire</option>
                                </Form.Select>
                            </Form.Group>
                            {data.type_candidat ==='militaire' ?<Input type='text' className='col-md-6 col-sm-12' label="Matricule Militaire" name='matricule' value={data.matricule} onChange={handleChange} /> : null}
                            {showMatricule && (
                <span className={`${valid.isValidMatricule ? 'text-success' : 'text-danger'}`}>
                    {valid.isValidMatricule ? 'Format de Matricule Correct' : 'Format de Matricule Incorrect'}
                </span>
            )}
                                </div>
                                <Form.Group>
                                <Form.Label style={{color:'#FFD524'}} className='fw-medium'>Choix du Centre d'examen <span style={{color:'red'}}>*</span></Form.Label>
                                <Form.Select name='centre_examen' required value={data.centre_examen} onChange={handleChange}>
                                    <option value="">--- Veuiller choisir un centre ---</option>
                                    <option value="Dakar">Dakar</option>
                                    <option value="Kaolack">Kaolack</option>
                                    <option value="Saint-Louis">Saint-Louis</option>
                                    <option value="Thies">Thies</option>
                                    <option value="Ziguinchor">Ziguinchor</option>
                                </Form.Select>
                                </Form.Group>                                        
                            {controllerChoice ? <div className="alert alert-danger" role="alert">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                                </svg> Vous devez choisir au moins une dans chaque département.
                                </div> : 
                            null}
                            {nmbrechoix && (
                            <div className="alert alert-warning" role="alert">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                    <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                    <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                                    </svg> Vous devez choisir au moins deux filières .
                                </div>
                            )}
                            <div className="head">
                                <span className='text-bold'>Dossiers à fournir</span> 
                            </div>
                                <div id="files">
                                    <><InputF required={true} className="rounded-left mb-2" label="Demande manuscrite (PDF)" type='file' name='autorisation' onChange={(e) => handleFileChange(e,"autorisation")} />
                                    {!selectedFiles["autorisation"]? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null}
                                    </> 
                                    <InputF required={true} className="rounded-left mb-2" label="copie légalisée du certificat de nationalité sénégalaise (PDF)" type='file' name='certificat_nationnalite' onChange={(e) => handleFileChange(e,"certificat_nationnalite")} />
                                    {!selectedFiles["certificat_nationnalite"]? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null}

                                    <InputF required={true} className="rounded-left mb-2" label="Extrait de naissance de moins de 3 mois (PDF)" type='file' name='extrait_naissance' onChange={(e) => handleFileChange(e,"extrait_naissance")} />
                                    {!selectedFiles["extrait_naissance"]? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null}

                                    <InputF required={true} className="rounded-left mb-2" label="Photo d'identité (JPG, JPEG)" type='file' name='photo' onChange={(e) => handleFileChange(e,"photo")} />
                                    {!selectedFiles["photo"]? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null}
                                    
                                    <InputF required={true} className="rounded-left mb-2" label="casier judiciaire n°3 datant de moins de trois mois (PDF)" type='file' name='casier' onChange={(e) => handleFileChange(e,"casier")} />
                                    {!selectedFiles["casier"]? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null}

                                    <InputF required={true} className="rounded-left mb-2" label={data.type_concours ==='Officier' ? 'diplome de licence ou certificat d\'inscription en année de licence scientifique (PDF)' : 'diplome de bac ou certificat d\'inscription en classe de Terminale (PDF)'} type='file' name='copie_diplome_licence' onChange={(e) => handleFileChange(e,"copie_diplome_licence")} />
                                    {!selectedFiles["copie_diplome_licence"]? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null}

                                    <InputF required={true}  className="rounded-left mb-2" label="certificat d’aptitude médicale datant de moins de trois mois, délivré par un médecin militaire et attestant du profil exigé (PDF)" type='file' name='certificat_medical' onChange={(e) => handleFileChange(e,"certificat_medical")} />
                                    {!selectedFiles["certificat_medical"]? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null}
{/* 
                                    <InputF required={true} id="bulletin" className="rounded-left mb-2" label="Bulletin Seconde deuxieme semestre" type='file' name='certificat_nationnalite2' onChange={(e) => handleFileChange(e,"certificat_nationnalite2")} />
                                    {!selectedFiles["certificat_nationnalite2"]? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null} */}
                                </div>
                        </div>
                    )}
                    {/* Fin de step 2 */}
                    {errors ? <><div className="alert alert-danger" role="alert">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                                </svg> Veuiler remplir tous les champs
                            </div></> : null}
                            <div id="buttonsPay">
                    {step > 1 && (
                    <Button variant='primary' className='float-start' size="md" onClick={handlePreviousStep}>Précedent</Button>
                    )}
                    {step < 2 && (
                // <Button className='float-end mb-2' variant='primary' size="md" onClick={handleUpdate}>Suivant</Button>a considréer apres deploiement
                <Button className='float-end mb-2' variant='primary' size="md" onClick={handleNextStep}>Suivant</Button>
            )}
                    {step === 2 && 
                        <Button
                            size='md'
                            variant='primary' 
                            disabled={!valid.isValidEmail || !valid.isValidPhoneNumber || !checked || older  || photo ||  !requiredOK || controllerChoice || nmbrechoix}
                            type='submit'
                            role='submit'
                            className ='float-end btn-submit '
                            >
                                {requiredOK ? (<span>Payer avec {data.operateur}</span>)  : <FontAwesomeIcon icon={faBan} className='color-logo' />} 
                                {submitted ? 
                                    <Spinner animation="grow"
                                        size="sm" role="status"
                                    >
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner> 
                                : null} 
                        </Button>  }
                    </div>
                </Form>
            </div>
        </Container>
        </>
    )
}
export default Pay