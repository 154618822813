import './Home.css';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { Form, Row, Col, Spinner } from 'react-bootstrap';
import logo from '../../img/logo_uam.png';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import {Link, useLocation} from 'react-router-dom';
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@mui/material/Alert';
import guide from '../../docs/Mode opératoire pour les candidatures du concours_2023.pdf'
function Home() {
  const Test = ({open , handleClose, message}) => {
    return (
      <>
  <Snackbar open={open} autoHideDuration={2000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleClose}>
          <MuiAlert onClose={handleClose} elevation={6} severity="error" sx={{ width: '100%' }}>
           {message}
          </MuiAlert>
        </Snackbar>
      </>
    )
  }
  const navigate = useNavigate();
  const [isopen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('');
  const [show, setShow] = useState(false);
  const {search} = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose2 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setIsOpen(false);
    };
  const Alert = () => {
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body className='alert alert-danger'> Veuiller enter un email valide (*)</Modal.Body>
            </Modal>
        </>
    )
}
const Delay = () => {
  return (
    <>
      <Modal show={delay} onHide={handleClose3}>
              <Modal.Body className='alert alert-danger'> Lés dépots sont clôturés ! </Modal.Body>
          </Modal>
    </>
  )
}
  useEffect(() => {
    // navigate('/depot')
    const match = search.match(/email=(.*)/);
    const emailFromURL = match?.[1];
    if (emailFromURL){
      navigate(`/depot?email=${emailFromURL}`);
  }
  })
  const [delay, setDelay] = useState('')
  const handleClose3 = () => setDelay(false);
  const handleDelay = (e) => {
    if (e.target) {
      setDelay(true)
    }
    console.log('Lés dépots sont clôturés ! ')
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)
    console.log("en cours d'envoie ...")
    if (!email) {
      setShow(true)
      setSubmitted(false)
      return 
    }
    // console.log(email)
    axios({
        url:"https://depot.uam.sn/api/v1/paiement/confirmed/",
        method: 'POST',
        data: {
            email : email
        }
    })
    .then(result => {
      // console.log(result)
        if (result.data.detail) {
            setSubmitted(false)
            // console.log(result)
            // console.log(result?.data?.detail)
            setMessage(result?.data?.detail)
            setIsOpen(true)
        } else {
          setIsOpen(true)
          if (result.status === 200 ){
            if (result.data.amount==='10125' && result.data.campagne ==='concours') {
              navigate(`/depot?email=${email}`)
            } else {
              setSubmitted(false)
            setMessage('Vous devez effectuer d\'abord le paiement des frais de dossier')
            setIsOpen(true)
            }
            // setCreds({...creds, email : result?.data?.email, num_transaction:result?.data?.num_transaction}); pour le concours.
          }
        }
    })
    .catch((error) => {
      setSubmitted(false)
      setIsOpen(true)
      setMessage('Erreur veuiller réessayez')
      console.log('Error:', error);
    });
  }
  const handleChange = (e) => {
    const value = e.target.value
    setEmail(value)
  }
  // }

  return (
    <>
    {Alert()}
    {Delay()}
    <Test open={isopen} handleClose={handleClose2} message={message} />
    <Container className='home'>
      <div className='mt-5 homeconcours' id='main'>
        <div id='head' className='d-flex flex-row flex-sm-col'>
          <div>
            <Image src={logo} width={239} height={220} />
          </div>
          <div className='' id='texthead'>
            <p>Université Amadou Mahtar Mbow </p>
            <p>Admission UAM 2023</p>
          </div>
        </div>
        <Form className='form'>
          <Row className=''>
            <Col  sm={6}>
              <Form.Group  className='mb-2'>
                <Form.Control 
                  type='email' 
                  placeholder='Saisissez votre email' 
                  className='mb-0'
                  size= "md"
                  name="email"
                  autoFocus
                  required
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
             <Col sm={6}>
             <Link to='#'>
                <Button variant="primary" onClick={handleSubmit} className='' size="md pl-3">
                  S'inscrire
                  {submitted ? <Spinner animation="grow" size="sm" role="status" /> : null}
                </Button>
              </Link>
            </Col>
          </Row>
        </Form>
        <Row className='' id='test'>
            <Col  sm={6}>
                <Link to='/pay'>
                  <Button variant="primary" className='mb-2' size="md" onClick={handleDelay}>
                    Payer les frais de candidature
                  </Button>
                </Link>
            </Col>
            {/* <Col  sm={6}>
            <Link to={guide} target='_blank'>
                  <Button variant="primary" className='mb-2' size="md">
                    Guide de la plateforme
                  </Button>
                </Link>
            </Col> */}
          </Row>
      </div>
    </Container>
    </>
  );
}

export default Home;
