
import { useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import {Dropdown, Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from '../../../src/img/uam_logo.png'
import './Navbar.css'
import { Link } from 'react-router-dom';
export default function Navbar(){
    const {auth, setAuth} = useContext(AuthContext);
    const navigate = useNavigate();
    return (<>
            <div className='navHome'>
                <nav className=" navbar navbar-expand-lg bg-default justify-content-start ">
                    <div className='d-flex flex-grow-1 '>
                        {/* <Link to='/'>
                            <img src={logo}  alt={""} ></img>
                        </Link> */}
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className="container-fluid ">
                            <Link to='#'> {/* /dashboard/candidats*/ }
                                <Button variant="primary" className='pl-4' size="md">
                                    Voir tous les Candidats
                                </Button>
                            </Link>
                        </div>
                        <div id='dropdown' className='float-end'>
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                {auth.email}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href='/'  onClick={() => {setAuth({});navigate("/"); }}>Deconnexion</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </nav>
            </div>
    </>

    );
}