import React, { useState } from 'react';
import './Pay.css';
import logo from '../../img/logo_uam.png'
import wave from '../../img/wave.jpg'
import om from '../../img/OM.jpg'
import axios from 'axios';
import freemoney from '../../img/free-money.jpg'
import expresso from '../../img/expresso.jpg'
import bank from'../../img/carte-bancaire-50px.svg'
import { Image, Form,Container,Button,Spinner } from 'react-bootstrap';
import IdTransaction from '../../components/idTransaction/IdTransaction';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faL } from "@fortawesome/free-solid-svg-icons";
import AlerteMessage from '../../components/AlerteMessage';
import { useEffect } from 'react';
import { DateRange } from '@mui/icons-material';

function PayUniforme ({prix=10000}) {
    const [data, setData] = useState('');
    const [show, setShow] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [checked, SetChecked] = useState(false);
    const [open, setOpen] = useState (false);
    const [message, setMessage] = useState (false);
    const [auth, setAuth] = useState(true)
    const [valid, SetIsValid] = useState({
        isValidEmail: false,
        isValidPhoneNumber: false,
      });
    const handleChange = (e) => {
        const name= e.target.name;
        const value = e.target.value;
        // setData({ ...data, [name]: value }); //clé valeur du champs
        setData((prevData) => ({ ...prevData, [name]: value, pays: prix }));
        if (name === 'telephone' ) {
            const phoneRegex = /^\d{1,9}$/
;  //validation numéro de téléphone
            SetIsValid((valid) => ({
                ...valid,
                isValidPhoneNumber: phoneRegex.test(value),
            }));
        }
        if (name === 'email') {
            //validation email
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            SetIsValid((valid) => ({
                ...valid,
                isValidEmail: emailRegex.test(value),
            }));
        }
        if (name === 'operateur') {
            SetChecked(e.target.checked)
        }
    }
    const handleClose3 = () => {
        setOpen(false)
    }
    let NotComplete = true;
    if (data.campagne ==='admission' || data.campagne==='concours') {
        if (!valid.isValidEmail || !valid.isValidPhoneNumber || !checked || !data.campagne) {
            NotComplete = false;
            console.log('test')
        }
    } else {
        if (!data.campagne || !valid.isValidPhoneNumber || !checked || !data.codePermanent) {
            NotComplete = false
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        // if (data.campagne==='uniforme') {
        //     data['email']='support@uam.edu.sn'
        // }
        // if (data.campagne==='reinscription') {
        //     data['email']='support@uam.edu.sn'
        // }
        console.log(prix)
        data['email']=prix.email
        data['codePermanent'] = prix.codePermanent
        console.log(data)
        var url 
        if (data.campagne ==='uniforme' || data.campagne ==='reinscription' || data.campagne ==='master' || data.campagne ==='inscriptions') {
            if (data.campagne ==='reinscription' || data.campagne ==='master') {
                // data.campagne ='reinscription'
                url = `https://depot.uam.sn/api/v1/reinscription/confirmed/?codePermanent=${data.codePermanent}`
            } else {
                // url = `https://depot.uam.sn/api/v1/reinscription/confirm/?codePermanent=${data.codePermanent}`
                url = `https://depot.uam.sn/api/v1/inscription/confirm/?codePermanent=${data.codePermanent}`
                // url = `http://127.0.0.1:8000/api/v1/${data.campagne}/confirm/?codePermanent=${data.codePermanent}`
            }
        fetch(url)
        .then(response => {
            if (!response.ok) {
            throw new Error(`Erreur HTTP! Statut: ${response.status}`);
            }
            return response.json();
        })
        .then(result => {
            // console.log(result.email)
            if (result.detail) {
                if (result.detail ==='Votre dossier n\'as pas été enregistré') {
                    setMessage('Vous n\'etes pas autorisés à payer')
                    setSubmitted(false);
                    setOpen(true)
                    // return
                } else{
                    setMessage(result.detail)
                }
                setSubmitted(false);
                setOpen(true)
                // return
            }
            // data['email'] = 'biteye.baye@uam.edu.sn'
            console.log("on doit lancer la requete")
            // if (result.data.mail_ins){
            //     // data['email'] = result?.mail_ins
            //     console.log(result.data.mail_ins)
                // data['email'] = result?.data.email
                axios({
                    url:'https://depot.uam.sn/api/v1/paiement/',
                    method: 'POST',
                    data:data
                })
                .then(result => {
                    console.log("Initialisation Success");
                    console.log('Data from API :', result.data);
                    if (result.data.message==='OK') {
                        console.log(result.data)
                        setSubmitted(false);
                        setShow(true);
                        if (result.data.paymentURL) {
                            window.location.href = result.data.paymentURL;
                        //    window.open(result.data.paymentURL, "_blank");
                        }
                    }
                    //window.location.href = result.data.paymentURL;
                })
                .catch((error) => {
                    setSubmitted(false);
                    if (error.response.status === '500') {
                        console.log(error.response.data)
                        setOpen(true)
                        setMessage('Impossible d\'initier un paiement ')
                    } else {
                        if (error.response.data.detail) {
                            setOpen(true)
                            setMessage(error.response.data.detail)
                        }
                    }
                    // console.error('Error:', error);
                    setSubmitted(false);
                });
            // } else if (result.detail) {
            //     setSubmitted(false);
            //     setMessage('Impossible d\'initier un paiement ')
            //     setOpen(true)
            // }
            
        })
        .catch(error => {
            setSubmitted(false);
            console.error('Erreur lors de la requête:', error);
            setMessage('Impossible d\'initier un paiement ')
            setOpen(true)
        });
        } else {
            axios({
                url:'https://depot.uam.sn/api/v1/paiement/',
                method: 'POST',
                data:data
                    })
                    .then(result => {
                        console.log("Initialisation Success");
                        console.log('Data from API :', result.data);
                        if (result.data.message==='OK') {
                            console.log(result.data)
                            setSubmitted(false);
                            setShow(true);
                            if (result.data.paymentURL) {
                                window.location.href = result.data.paymentURL;
                            //    window.open(result.data.paymentURL, "_blank");
                            }
                        }
                        //window.location.href = result.data.paymentURL;
                    })
                    .catch((error) => {
                        setSubmitted(false);
                        if (error.response.status === '500') {
                            console.log(error.response.data)
                            setOpen(true)
                            setMessage('Impossible d\'initier un paiement ')
                        } else {
                            if (error.response.data.detail) {
                                setOpen(true)
                                setMessage(error.response.data.detail)
                            }
                        }
                        // console.error('Error:', error);
                        setSubmitted(false);
                    });
        }
        // data['email'] ='biteye.baye@uam.edu.sn'
            
        
    }
    return (
        <>
        <AlerteMessage open={open} handleClose={handleClose3} message={message} />
        <IdTransaction show={show} email={data.email} handleClose={() => 
            {setShow(false)}
            } />
        <Container className='pay' id='main'>
            <div className='d-flex flex-row' id='payHead'>
                <div>
                    <Image src={logo} width={239} height={220} />
                </div>
                <div className='' id='texthead'>
                    <p>Université Amadou Mahtar Mbow </p>
                    <p>Année Académique 2023-2024</p>
                </div>
            </div>
            <div>
                <Form onSubmit={handleSubmit} className=''>
                    <div className='p-4 row'>
                        {auth ? 
                        <>
                            <Form.Group className='col-md-8 col-sm-12'>
                                <Form.Label>Campagne</Form.Label>
                                <Form.Select name='campagne' onChange={handleChange}>
                                    <option value="">Veuiller selectionner une campagne de paiement</option>
                                    {/* <option value="uniforme">Uniforme</option> */}
                                    <option  value="inscription">Inscription</option>
                                    {/* <option value="reinscription">Réinscription</option>
                                    <option value="master">Inscription Master</option> */}
                                    {/* <option disabled value="concours">Concours</option> */}
                                    {/* <option value="admission">Admission</option> */}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className='col-md-6 col-sm-12 mb-3'>
                                <Form.Label>Téléphone</Form.Label>
                                <Form.Control 
                                    placeholder='XXXXXXXXX'
                                    size='md'
                                    type='number'
                                    name='telephone'
                                    className='p-2 custom-email-input'
                                    onChange={handleChange}
                                    autoFocus
                                    required
                                />
                            </Form.Group>
                            {data.campagne ==='admission' ? 
                                <>
                                    <Form.Group className='col-md-6 col-sm-12 mb-3'>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control 
                                        placeholder='Email'
                                        size='md'
                                        type='email'
                                        name='email'
                                        className='p-2 custom-email-input'
                                        onChange={handleChange}
                                        required
                                    />
                                    </Form.Group>
                                </> : 
                                    <>
                                    {data.campagne ==='inscription' ? <> <Form.Group className='col-md-6 col-sm-12'>
                                        <Form.Label>Numéro de Table BAC</Form.Label>
                                        <Form.Control 
                                            placeholder='Numéro Table BAC'
                                            size='md'
                                            type='text'
                                            name='codePermanent'
                                            className='p-2 custom-email-input'
                                            onChange={handleChange}
                                            autoFocus
                                            required
                                        />
                                    </Form.Group></> : 
                                    <>
                                        <Form.Group className='col-md-6 col-sm-12'>
                                        <Form.Label>Code Permanent</Form.Label>
                                        <Form.Control 
                                            placeholder='Code Permanent'
                                            size='md'
                                            type='text'
                                            name='codePermanent'
                                            className='p-2 custom-email-input'
                                            onChange={handleChange}
                                            autoFocus
                                            required
                                        />
                                    </Form.Group></>
                                    }
                                    </>
                            } 
                            
                            <Form.Group>
                                <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    name="operateur"
                                    value="wave"
                                    label = {
                                        <Image src={wave} height={80} width={80} alt="wave" />
                                    }
                                />
                                <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    name="operateur"
                                    value="orange-money"
                                    label = {
                                        <Image src={om} height={80} width={80} alt="wave" />
                                    }
                                />
                                <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    name="operateur"
                                    value="free-money"
                                    label = {
                                        <Image src={freemoney} height={80} width={80} alt="wave" />
                                    }
                                />
                                <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    name="operateur"
                                    value="e-money"
                                    label = {
                                        <Image src={expresso} height={80} width={80} alt="wave" />
                                    }
                                />
                                {/* <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    name="operateur"
                                    value="mobilebank"
                                    label = {
                                        <Image src={bank} height={80} width={80} alt="cartebancaire" />
                                    }
                                /> */}
                            </Form.Group>
                            {data.campagne ? 
                            <>
                                {data.campagne==='uniforme' ? 
                            <>
                                <div>
                                <p className='text-danger ml-2' id='frais'>Le paiement des droits d'inscription, non remboursables, sont de vingt mille (20 000) F. CFA + frais de 250 F. CFA</p>
                            </div>
                            </> : 
                            null}
                            </> : null}
                            {data.campagne ==='master' ? <><div>
                                <p className='text-danger ml-2' id='frais'>Le paiement des droits d'inscription, non remboursables, sont de cinquante milles (50 000) F. CFA + frais de 625 F. CFA</p>
                            </div></> : null}
                            {data.campagne ==='reinscription' ? <><div>
                                <p className='text-danger ml-2' id='frais'>Le paiement des droits d'inscription, non remboursables, sont de vingt cinq milles (25 000) F. CFA + frais de 315 F. CFA</p>
                            </div></> : null}
                        </>
                        : 
                        <>
                            <Form.Group className='col-md-6 col-sm-12'>
                                <Form.Label>Numéro de Table BAC</Form.Label>
                                <Form.Control 
                                    placeholder='501577'
                                    size='md'
                                    type='number'
                                    name='codePermanent'
                                    className='p-2 custom-email-input'
                                    onChange={handleChange}
                                    autoFocus
                                    required
                                />
                            </Form.Group>
                            <Form.Group className='col-md-6 col-sm-12'>
                                <Form.Label>Campagne</Form.Label>
                                <Form.Select name='campagne'>
                                    <option value="uniforme">Uniforme</option>
                                    <option disabled value="inscription">Inscription</option>
                                    <option disabled value="reinscription">Réinscription</option>
                                    <option disabled value="concours">Concours</option>
                                    <option disabled value="admission">Admission</option>
                                </Form.Select>
                            </Form.Group>
                        </>
                        }
                    </div>
                    
                   
		
		{/* <input onClick={callTouchPay} className='intouch' type='button'  value='Payer avec carte'/> */}

                    <Button
                        onClick={data.operateur === 'mobilebank' ? 'callTouchPay' : handleSubmit}
                        variant='primary' 
                        disabled={!NotComplete} type='button'
                        className ='w-75 btn-submit'
                        >
                            {NotComplete ? (<span>Payer avec {data.operateur}</span>)  : <FontAwesomeIcon icon={faBan} className='color-logo' />} 
                            {submitted ? 
                                <Spinner animation="grow"
                                    size="sm" role="status"
                                >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> 
                            : null} 
                    </Button>
                </Form>
            </div>
        </Container>
        </>
    )
}
export default PayUniforme