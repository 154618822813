import React, { useState } from 'react';
import './restaurant.css';
import logo from '../../img/logo_uam.png'
import wave from '../../img/wave.jpg'
import om from '../../img/OM.jpg'
import axios from 'axios';
import freemoney from '../../img/free-money.jpg'
import expresso from '../../img/expresso.jpg'
import bank from'../../img/carte-bancaire-50px.svg'
import { Image, Form,Container,Button,Spinner } from 'react-bootstrap';
import IdTransaction from '../../components/idTransaction/IdTransaction';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faL } from "@fortawesome/free-solid-svg-icons";
import AlerteMessage from '../../components/AlerteMessage';
import { useEffect } from 'react';
import { DateRange } from '@mui/icons-material';

function Restaurant ({prix=10000}) {
    const [data, setData] = useState('');
    const [show, setShow] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [checked, SetChecked] = useState(false);
    const [open, setOpen] = useState (false);
    const [message, setMessage] = useState (false);
    const [auth, setAuth] = useState(true)
    const [valid, SetIsValid] = useState({
        isValidEmail: false,
        isValidPhoneNumber: false,
      });
    const handleChange = (e) => {
        const name= e.target.name;
        const value = e.target.value;
        // setData({ ...data, [name]: value }); //clé valeur du champs
        setData((prevData) => ({ ...prevData, [name]: value, pays: prix }));
        if (name === 'telephone' ) {
            const phoneRegex = /^\d{1,9}$/
;  //validation numéro de téléphone
            SetIsValid((valid) => ({
                ...valid,
                isValidPhoneNumber: phoneRegex.test(value),
            }));
        }
        if (name === 'email') {
            //validation email
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            SetIsValid((valid) => ({
                ...valid,
                isValidEmail: emailRegex.test(value),
            }));
        }
        if (name === 'operateur') {
            SetChecked(e.target.checked)
        }
    }
    const handleClose3 = () => {
        setOpen(false)
    }
    let NotComplete = true;
    if (data.campagne !=='uniforme') {
        if (!valid.isValidEmail || !valid.isValidPhoneNumber || !checked || !data.campagne) {
            NotComplete = false;
        }
    } else {
        if (!data.campagne || !valid.isValidPhoneNumber || !checked || !data.codePermanent) {
            NotComplete = false
        }
    }
    // console.log(NotComplete)
    useEffect(() => {
        // Chargement du script touchpay
        const script = document.createElement('script');
        script.src = 'https://touchpay.gutouch.net/touchpayv2/script/touchpaynr/prod_touchpay-0.0.1.js';
        script.type = 'text/javascript';
        script.async = true;
        document.head.appendChild(script);
        return () => {
          document.head.removeChild(script);
        };
      }, []);
      const callTouchPay = () => {
        if (window.sendPaymentInfos) {
            console.log(prix.nationnalite)
            if (prix.nationnalite ==='66') {
                prix.nationnalite = 202500
            } else {
                prix.nationnalite =25313
            }
            var id=new Date().getTime()
            // alert(id)
            data['amount'] = id
          console.log(data.operateur,data.pays,data.amount)
          axios({
            url:'https://depot.uam.sn/api/v1/paiement/',
            method: 'POST',
            data:data
        })
        .then(result => {
            console.log("Initialisation Success");
            console.log('Data from API :', result.data);
            window.sendPaymentInfos(
                id,
                'UAMM19171',
                'DA3OxeOUcWHCn7Bu5O7AfIzfPVFHhEXuRIVQnPo6Gkb9qn6puM',
                'uamm.sn',
                `https://depot.uam.sn/`,
                'https://depot.uam.sn',
               prix.nationnalite,
                'Dakar',
                `${data.email}`,
                prix.prenom,
                prix.nom,
                `${data.telephone}`
              );
            if (result.data.message==='OK') {
                console.log(result.data)
            }
        })
        .catch((error) => {
            console.error(error)
        });
          console.log()
        } else {
          console.error('sendPaymentInfos is not defined');
        }
      };
   // console.log("Not completed " +NotComplete)
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true); 
        // data['email'] ='biteye.baye@uam.edu.sn'
        data['campagne'] = 'restaurant'
        console.log(data)
            axios({
            url:'https://depot.uam.sn/api/v1/paiements/',
            method: 'POST',
            data:data
                })
                .then(result => {
                    console.log("Initialisation Success");
                    console.log('Data from API :', result.data);
                    if (result.data.message==='OK') {
                        console.log(result.data)
                        setSubmitted(false);
                        setShow(true);
                        if (result.data.paymentURL) {
                            window.location.href = result.data.paymentURL;
                        //    window.open(result.data.paymentURL, "_blank");
                        }
                    }
                    //window.location.href = result.data.paymentURL;
                })
                .catch((error) => {
                    setSubmitted(false);
                    if (error.response.status === '500') {
                        console.log(error.response.data)
                        setOpen(true)
                        setMessage('Impossible d\'initier un paiement ')
                    } else {
                        if (error.response.data.detail) {
                            setOpen(true)
                            setMessage(error.response.data.detail)
                        }
                    }
                    // console.error('Error:', error);
                    setSubmitted(false);
                });
    }
    const plats = [
        {
            'nom' : 'THIEB',
            'price':'1500F',
        },
        {
            'nom' : 'Yassa',
            'price':'1500F',
        },
        {
            'nom' : 'MAFE',
            'price':'1500F',
        },
        {
            'nom' : 'Soupou KANDIA',
            'price':'150f',
        },
        {
            'nom' : 'PAELLA',
            'price':'2000F',
        },
    ]
    
        plats.map((item) => {
            return item['nom']
        })
    return (
        <>
        <AlerteMessage open={open} handleClose={handleClose3} message={message} />
        <IdTransaction show={show} email={data.email} handleClose={() => 
            {setShow(false)}
            } />
        <Container className='pay' id='main'>
            <div className='d-flex flex-row' id='payHead'>
                <div>
                    <Image src={logo} width={239} height={220} />
                </div>
                <div className='' id='texthead'>
                    <p>Université Amadou Mahtar Mbow </p>
                    <p>Commande Restaurant</p>
                </div>
            </div>
            <div>
                <Form onSubmit={handleSubmit} className=''>
                    <div className='p-4 row'>
                        {auth ? 
                        <>
                            <Form.Group className='col-md-6 col-sm-12'>
                                <Form.Label>Plat Commandé</Form.Label>
                                <Form.Select name='plat' onChange={handleChange}>
                                    {plats.map((item,index) =>(
                                        <>
                                        <option key={index}>{item.nom}</option>
                                        <span className="text-muted">{item.price}</span>
                                        </>
                                    ) )}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className='col-md-6 col-sm-12'>
                                <Form.Label>Nom du Client </Form.Label>
                                <Form.Control 
                                    placeholder=''
                                    size='md'
                                    type='text'
                                    name='nom'
                                    className='p-2 custom-email-input'
                                    onChange={handleChange}
                                    autoFocus
                                    required
                                />
                            </Form.Group>
                            <Form.Group className='col-md-6 col-sm-12 mb-3'>
                                <Form.Label>Téléphone</Form.Label>
                                <Form.Control 
                                    placeholder='XXXXXXXXX'
                                    size='md'
                                    type='number'
                                    name='telephone'
                                    className='p-2 custom-email-input'
                                    onChange={handleChange}
                                    autoFocus
                                    required
                                />
                            </Form.Group>
                            {data.campagne !=='uniforme' ? 
                                <>
                                    <Form.Group className='col-md-6 col-sm-12 mb-3'>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control 
                                        placeholder='Email'
                                        size='md'
                                        type='email'
                                        name='email'
                                        className='p-2 custom-email-input'
                                        onChange={handleChange}
                                        required
                                    />
                                    </Form.Group>
                                </> : 
                                    <Form.Group className='col-md-6 col-sm-12'>
                                    <Form.Label>Code Permanent</Form.Label>
                                    <Form.Control 
                                        placeholder='501577'
                                        size='md'
                                        type='number'
                                        name='codePermanent'
                                        className='p-2 custom-email-input'
                                        onChange={handleChange}
                                        autoFocus
                                        required
                                    />
                                </Form.Group>
                            } 
                            
                            <Form.Group>
                                <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    name="operateur"
                                    value="wave"
                                    label = {
                                        <Image src={wave} height={80} width={80} alt="wave" />
                                    }
                                />
                                <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    name="operateur"
                                    value="orange-money"
                                    label = {
                                        <Image src={om} height={80} width={80} alt="wave" />
                                    }
                                />
                                <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    name="operateur"
                                    value="free-money"
                                    label = {
                                        <Image src={freemoney} height={80} width={80} alt="wave" />
                                    }
                                />
                                <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    name="operateur"
                                    value="e-money"
                                    label = {
                                        <Image src={expresso} height={80} width={80} alt="wave" />
                                    }
                                />
                                {/* <Form.Check
                                    inline
                                    onChange={handleChange}
                                    type="radio"
                                    id="wave"
                                    name="operateur"
                                    value="mobilebank"
                                    label = {
                                        <Image src={bank} height={80} width={80} alt="cartebancaire" />
                                    }
                                /> */}
                            </Form.Group>
                                {(data.plat ==='MAFE' || data.plat ==='YASSA' || data.plat ==='THIEB' )  ? <>
                                    <div>
                                        <p className='text-danger ml-2' id='frais'>Le paiement des droits d'inscription, non remboursables, sont 1000 F. CFA + frais de 250 F. CFA</p>
                                    </div> 
                                </> : <div>
                                        <p className='text-danger ml-2' id='frais'>Le paiement des droits d'inscription, non remboursables, sont 150 F. CFA + frais de 250 F. CFA</p>
                                    </div> }
                            
                        </>
                        : 
                        <>
                            <Form.Group className='col-md-6 col-sm-12'>
                                <Form.Label>Code Permanent</Form.Label>
                                <Form.Control 
                                    placeholder='501577'
                                    size='md'
                                    type='number'
                                    name='codePermanent'
                                    className='p-2 custom-email-input'
                                    onChange={handleChange}
                                    autoFocus
                                    required
                                />
                            </Form.Group>
                            <Form.Group className='col-md-6 col-sm-12'>
                                <Form.Label>Campagne</Form.Label>
                                <Form.Select name='campagne'>
                                    <option value="uniforme">Uniforme</option>
                                    <option disabled value="inscription">Inscription</option>
                                    <option disabled value="reinscription">Réinscription</option>
                                    <option disabled value="concours">Concours</option>
                                    <option disabled value="admission">Admission</option>
                                </Form.Select>
                            </Form.Group>
                        </>
                        }
                    </div>
                    
                   
		
		{/* <input onClick={callTouchPay} className='intouch' type='button'  value='Payer avec carte'/> */}

                    <Button
                        onClick={data.operateur === 'mobilebank' ? callTouchPay : handleSubmit}
                        variant='primary' 
                        disabled={!NotComplete} type='button'
                        className ='w-75 btn-submit'
                        >
                            {NotComplete ? (<span>Payer avec {data.operateur}</span>)  : <FontAwesomeIcon icon={faBan} className='color-logo' />} 
                            {submitted ? 
                                <Spinner animation="grow"
                                    size="sm" role="status"
                                >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> 
                            : null} 
                    </Button>
                </Form>
            </div>
        </Container>
        </>
    )
}
export default Restaurant
