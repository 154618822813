import React, {useContext, useEffect, useState} from 'react';
import {Form,Button,Spinner, Modal} from 'react-bootstrap';
import axios from 'axios';
import CredsContext from "../../context/CredsContext";
import Recepisse from '../../components/RecepisseDepot';
import moment from 'moment';
import {useNavigate, useLocation} from "react-router-dom";
import { Link } from 'react-router-dom';
function InputOption ({value, label, disabled}) {
    return (
        <option disabled={disabled} value={value}>{label}</option>
    )
}

function Input ({type,name,min,max,onChange,label,className,id}) {
  return (
    <>
        <Form.Group className={className} id={id}>
                <Form.Label className='fw-medium'>{label.charAt(0).toUpperCase() + label.slice(1)} <span style={{color:'red'}}>*</span></Form.Label>
                  <Form.Control
                    className={className}
                    size='md'
                    step="0.01"
                    min={min}
                    required
                    max={max}
                    type={type}
                    name={name}
                    placeholder={label.charAt(0).toUpperCase() + label.slice(1)}
                    onChange={onChange}
                  />
        </Form.Group>
    </>
  )
}
function InputT ({type,name,min,max,onChange,label,className,id}) {
    return (
      <>
          <Form.Group className={className} id={id}>
                  <Form.Label className='fw-medium'>{label.charAt(0).toUpperCase() + label.slice(1)} <span style={{color:'red'}}>*</span></Form.Label>
                    <Form.Control
                      className={className}
                      size='md'
                      required
                      min={min}
                      max={max}
                      type={type}
                      name={name}
                      placeholder={label.charAt(0).toUpperCase() + label.slice(1)}
                      onChange={onChange}
                    />
          </Form.Group>
      </>
    )
  }
function InputCheckbox ({value, label, onChange}) {
    return (
        <label>
            <input type="checkbox" value={value} name='options' onChange={onChange} /> {label}
        </label>
    )
}
const InputCheck = () => {
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const newCount = isChecked ? selectedCount + 1 : selectedCount - 1;
    const optionValue = e.target.value;

    if (newCount <= 3) {
      setSelectedCount(newCount);
      if (isChecked) {
        setSelectedOptions([...selectedOptions, optionValue]);
      } else {
        setSelectedOptions(selectedOptions.filter(option => option !== optionValue));
      }
    } else {
      e.target.checked = false;
    }
  };
  useEffect(() => {
    console.log(selectedOptions)
  }, [selectedOptions])

  return (
    <div>
      <InputCheckbox value="HEEG" label="HEEG" onChange={handleCheckboxChange}/>
      <InputCheckbox value="ESTI" label="ESTI" onChange={handleCheckboxChange}/>
      <InputCheckbox value="ES2A" label="ES2A" onChange={handleCheckboxChange}/>
      <InputCheckbox value="ESMGE" label="ESMGE" onChange={handleCheckboxChange}/>
      <InputCheckbox value="ESU2A" label="ESU2A" onChange={handleCheckboxChange}/>
    </div>
  );
};

function InputF ({type,name,onChange,label,className,id}) {
    return (
        <>
            <Form.Group className={className} id={id}>
                <Form.Label className='fw-medium'>{label.charAt(0).toUpperCase() + label.slice(1)} <span style={{color:'red'}}>*</span>(jpg, jpeg, png, pdf)</Form.Label>
                    <Form.Control
                        className={className}
                        size='md'
                        accept=".jpg, .jpeg, .png, .pdf"
                        type={type}
                        name={name}
                        required
                        placeholder={label.charAt(0).toUpperCase() + label.slice(1)}
                        onChange={onChange}
                    />
            </Form.Group>
        </>
    )
}


const Depot = ({props}) => {
    const [valid, SetIsValid] = useState({
        isValidPhoneNumber: false,
      });
      const[ messageAlerte, setMessageAlerte] = useState('')
    const {creds, setCreds} = useContext(CredsContext);
    const { search } = useLocation();
    const [depotsuccess, setDeppotSuccess] = useState(false);
    const [older,setOlder] = useState(false)
    const maxFileSize = 1024 * 1024; //1Mo
    const [show, setShow] = useState(false);
    const [complete, setComplete] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const [selectedFiles, setSelectedFiles] = useState({
        "releve_bac":null,
        "copie_carte_id" : null,
        "bulletin_terminal" : null,
        "bulletin_premiere" : null,
        "bulletin_seconde" : null,
        "bulletin_premiere2" : null,
        "bulletin_seconde2" : null,
        "bulletin_terminal2" : null,
    });
    const [gestionfichier, setGestionFichier] = useState({  
        "bulletin_premiere2" : false,
        "bulletin_seconde2" : false,
        "bulletin_terminal2" : false
    });
    // const [gestionfichier, setGestionFichier] = useState(false);
    const [isInputVisible, setIsInputVisible] = useState(false); //etat du composant visible 
    const [isInputVisible2, setIsInputVisible2] = useState(false); //etat du composant visible 
    const [isInputVisible3, setIsInputVisible3] = useState(false); //etat du composant visible 
    const toggleInputVisibility = () => {
        setIsInputVisible(!isInputVisible);
    };
    const toggleInputVisibility2 = () => {
        setIsInputVisible2(!isInputVisible2);
    };
    const toggleInputVisibility3 = () => {
        setIsInputVisible3(!isInputVisible3);
    };
    const [submitted, setSubmitted] = useState(false);
    const [data, setData] = useState();  /*données d'un user avec dépot réussie */
//vérification emial 
    useEffect (() => {

        const match = search.match(/email=(.*)/);
        const email = match?.[1];
        // console.log(email)
        if (!email) {
            navigate("/");
        }
        axios({
            url:"https://depot.uam.sn/api/v1/paiement/confirmed/",
            method: 'POST',
            data: {
                email : email
            }
        })
        .then(result => {
            if (result?.data?.message) {
                console.log("impossile de retrouver le paiment");
                navigate("/");
            } else {
                 //console.log('donnes : ', result?.data);
                 setCreds({
                     ...creds,
                     email: result?.data?.email,
                     num_transaction: result?.data?.num_transaction
                 })
                 //console.log(creds)
            }
        })
        .catch((error) => {
            console.log('Error:', error);
        });
       }, [])
      // console.log(creds)
    const handleFileChange = (event, key) => {
        const file = event.target.files[0];
        if (file?.size > maxFileSize) {
            setSelectedFiles((prevSelectedFiles) => ({
                ...prevSelectedFiles,
                [key]: file
            }
            )
            );
            alert('fichier gros ')
        return ;
        } else {
            setSelectedFiles((prevSelectedFiles) => ({
                ...prevSelectedFiles,
                [key]: file}
          ));
          setGestionFichier(prevSelectedFiles => ({
            ...prevSelectedFiles,
            [key+2]: true
        }));
    }
    };
    const [dataFormEcole, setDataFormEcole] = useState({
        serie: 'null',
        premier_choix_ecole: 'null',
        // ... other form data
      });
    const [dataForm, setDataForm] = useState('');
    function handleChange (e) {
        const name= e.target.name;
        let value = e.target.value;
        if (name==="date_naissance") {
            const referenceDate = moment('2023-12-31');

    const dateNaissance = moment(value); // Remplacez cela par la date de naissance réelle
    const ageCalcul = referenceDate.diff(dateNaissance, 'years')
        //   const ageCalcul = moment().diff(value, 'years');
        //   console.log(ageCalcul)
          const maxAge = 22;
          if (ageCalcul > maxAge || ageCalcul < 14) {
            setOlder(true)
            setDataForm({ ...dataForm, [name]: null });
            // console.log(older)
            return
          } else {
            setOlder(false)
          }
        } if (name==="session" || name==="departement" ||name==="serie" || name==="ecole" || name==="region" || name==="centre_examen" || name==="second_choix_ecole" || name==="premier_choix_ecole"  || name==="troisieme_choix_ecole"  ) {
            if( value ==="null") {
                setDataForm({ ...dataForm, [name]: null });
                return
            }
        } if (name === 'telephone' ) {
            const phoneRegex = /^\d{9}$/;  //validation numéro de téléphone
            SetIsValid((valid) => ({
                ...valid,
                isValidPhoneNumber: phoneRegex.test(value),
            }));
        }
        setDataForm({ ...dataForm, [name]: value });
    }
    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const formData = new FormData();
            const nameFiles = ["releve_bac","copie_carte_id","bulletin_terminal","bulletin_premiere","bulletin_seconde","bulletin_terminal2","bulletin_premiere2","bulletin_seconde2"]
            for (const i in nameFiles) {
                if (selectedFiles[nameFiles[i]]) {
                    if ((selectedFiles[nameFiles[i]]).size <= maxFileSize) {
                        formData.append(nameFiles[i] , selectedFiles[nameFiles[i]])
                    } else {
                        console.log("fichier trop gros");
                    }
                }
            }
            formData.append("email" , creds?.email)
            formData.append("num_transaction" , creds?.num_transaction)
            for (let key in dataForm) {
                if (dataForm.hasOwnProperty(key)) {
                      if( dataForm[key] !== null) {
                        if (key === 'serie') {
                            if (dataForm[key] ==="L'1" || dataForm[key] === 'T1' || dataForm[key] ==='STEG' || dataForm[key] === 'T2' || dataForm[key] === 'LA' || dataForm[key] ==='L1' || dataForm[key] ==='STIDD' ) {
                                //console.log("peux faire qu'un seul choix ")
                                formData.append('second_choix_ecole',null)
                                formData.append('troisieme_choix_ecole',null)
                            }
                         }
                         if (key === 'serie') {
                            if (dataForm[key] ==="L2" ) {
                                formData.append('troisieme_choix_ecole',null)
                                if (dataForm["premier_choix_ecole"]){
                                    if (dataForm["moyenne_terminal"] <12 ) {
                                        formData.append('second_choix_ecole',null)
                                    }
                                }
                            }
                            if (dataForm[key] ==="F6" ) {
                                formData.append('troisieme_choix_ecole',null)
                            }

                            if (dataForm[key] ==="S1" || dataForm[key] ==="S1A" || dataForm[key] ==="S2" || dataForm[key] ==="S2A" || dataForm[key] ==="S3" || dataForm[key] ==="S4" || dataForm[key] ==="S5" || dataForm[key] ==="F6" ) {
                                if (dataForm["premier_choix_ecole"] === "HEEG" || dataForm["premier_choix_ecole"] === "ESU2A") {
                                    formData.append('troisieme_choix_ecole',null)
                                }
                            }

                                 // console.log(dataForm[key])
                                //  console.log('Passe au second tour')
                                //  console.log("Moyenne existe et est "+dataForm['moyenne_second_session'])
                                 //    dataForm['moyenne_second_session'] = 0;
                            // } else {
                            //  formData.append('moyenne_second_session',0)
                            // }
                         }
            
                        formData.append(key ,dataForm[key])
                      }
              }
            }
            const releve = formData.get('releve_bac')
            const fieldValue = formData.get('bulletin_terminal2');
            const fieldvalue2 = formData.get('bulletin_terminal');
           // console.log("etats bulletins terminales ", fieldValue,fieldvalue2)
           // console.log("Terminal ",gestionfichier.bulletin_terminal2)
            const fieldValue3 = formData.get('bulletin_premiere');
            const fieldvalue4 = formData.get('bulletin_premiere2')
           // console.log("etats bulletins premiers ", fieldvalue4,fieldValue3)
            //console.log("Premiere ",gestionfichier.bulletin_premiere2)
            const fieldValue5 = formData.get('bulletin_seconde');
            const fieldvalue6 = formData.get('bulletin_seconde2')
           // console.log("etats bulletins seconde ", fieldValue5,fieldvalue6)
           // console.log("Seconde ",gestionfichier.bulletin_seconde2)
            var c1 = formData.get('premier_choix_ecole');
            const s = formData.get('serie');
            var c2 = formData.get('second_choix_ecole');
            const c3 = formData.get('troisieme_choix_ecole');
            const region = formData.get('region');
            const dpt = formData.get('departement');
            const session = formData.get('session');
            const centre = formData.get('centre_examen');
            const moyenne_terminale = formData.get('moyenne_terminal');
            if (c1 === "ESU2A" && s === "L2" && moyenne_terminale < 12) {
                console.log('peux pas choisir HEEG comme choix 2')
                c2 = "test"
            }
            if (c2 === "ESU2A" && s === "L2" && moyenne_terminale < 12) {
                console.log('peux pas choisir HEEG comme choix 1')
                c1 = "test"
            }

            //bulletin terminal
            if (fieldValue && fieldvalue2) {
                //console.log("Bulletins terminal bien remplis")
                setGestionFichier(prevSelectedFiles => ({
                    ...prevSelectedFiles,
                    "bulletin_terminal2": true
                }));
            } else if (fieldValue && !fieldvalue2) {
                //console.log("Obligée de remplir bulletin 1 terminal")
            } else if (!fieldValue && fieldvalue2) {
                //console.log("correct peut envoyer terminal")
                // setGestionFichier(prevSelectedFiles => ({
                //     ...prevSelectedFiles,
                //     "bulletin_terminal2": true
                // }));
            }
/*Bulletin première  */
            if (fieldValue3 && fieldvalue4) {
                // console.log("Bulletins première bien remplis")
                setGestionFichier(prevSelectedFiles => ({
                    ...prevSelectedFiles,
                    "bulletin_premiere2": true
                }));
            } else if (!fieldValue3 && fieldvalue4) {
                console.log("Obligée de remplir bulletin 1 première")
            } else if (fieldValue3 && !fieldvalue4) {
                //console.log("correct peut envoyer première")
                // setGestionFichier(prevSelectedFiles => ({
                //     ...prevSelectedFiles,
                //     "bulletin_premiere2": true
                // }));
            }
/*Bulletin seconde  */

            if (fieldValue5 && fieldvalue6) {
                //console.log("Bulletins seconde bien remplis")
                setGestionFichier(prevSelectedFiles => ({
                    ...prevSelectedFiles,
                    "bulletin_seconde2": true
                }));
            } else if (!fieldValue5 && fieldvalue6) {
                //console.log("Obligée de remplir bulletin 1 seconde")
            } else if (fieldValue5 && !fieldvalue6) {
                // console.log("correct peut envoyer seconde")
                setGestionFichier(prevSelectedFiles => ({
                    ...prevSelectedFiles,
                    "bulletin_seconde2": true
                }));
            }
            for (let pair of formData.entries()) {
                if (pair[0] === "bulletin_terminal") {
                    console.log("Bulletin terminal 1 exact")
                }
                console.log(pair[0] + ':' + pair[1]);
              }
              var releve1 = false
              if (releve.size > maxFileSize) {
                releve1 = false
              } else 
              {
                releve1 = true
              }
            //   console.log(fieldValue)
           // console.log("etat de l'age ",older)
            //console.log("etats des bulletins ", gestionfichier.bulletin_terminal2 ,gestionfichier.bulletin_premiere2, gestionfichier.bulletin_seconde2)
            const fieldsCount = Array.from(formData.entries()).length;
            console.log("Champs remplis coorrectement "+fieldsCount)
            
            if (!valid.isValidPhoneNumber) {
                setMessageAlerte('Incorrect')
                setShow(true);
            } else if (!gestionfichier.bulletin_terminal2 || !gestionfichier.bulletin_premiere2 || !gestionfichier.bulletin_seconde2) {
                setShow(true);
            }
            else if (older || !c1 || !releve1 || !c2 || !c3 || !s || !region || !centre  || !session) {
                setShow(true);
            }
            else {
                setComplete(true)
                setSubmitted(true);
                console.log("Requete en cours d'envoi ...")
                const response = await axios.post(
                    'https://depot.uam.sn/api/v1/candidat/',
                    formData,
            {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                    });

            if (response.status === 200) {
                console.log(response)
                setSubmitted(false);
                // console.log('Files uploaded successfully');
                // console.log('Recepissé :' + response?.data?.data)
                const dataPersonDepot = response?.data?.data;
                setData(dataPersonDepot);
                setDeppotSuccess(true);
                // console.log("message : " + response.data.message);
                // console.log(data);
            } else {
                console.error('Error uploading files');
            }
        }
    } catch (error) {
        setSubmitted(false);
       console.error('Error uploading files', error);
    }
};
    const Alert = () => {
        return (
            <>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Body className='alert alert-warning'> Veuiller remplir tous les champs (*)</Modal.Body>
                </Modal>
            </>
        )
    }



  return (
    <>
    {Alert()}
    {depotsuccess ? <Recepisse  dataPerson={data}/> : null}
      <div className='container mb-2 ' id='mainDepot'>
        <div className='row'>
        <div id='nameModule' className=''>
            <p>Informations personnelles</p>
        </div>
        <div id='form' className=''>
          <Form onSubmit={handleSubmit}>
            <div className='row' id='test'>
            <Input className="col-md-6 col-sm-12"  type='text' label="Nom" name='nom' onChange={handleChange}/>
            <Input className="col-md-6 col-sm-12"  type='text' label="Prénom (s)" name='prenom' onChange={handleChange} />
            </div>
            <Form.Group>
              <div className='d-inline'>
                <Form.Check
                inline
                        type="radio"
                        label="Monsieur"
                        value="M"
                        required
                        name="civilite"
                        onChange={handleChange}
                    />
                </div>
                <div className='d-inline'>
                    <Form.Check
                        inline
                        type="radio"
                        label="Madame"
                        value="F"
                        required
                        name="civilite"
                        onChange={handleChange}
                    />
                </div>
            </Form.Group>
            <Input type='date' label="Date de Naissance (jour/mois/année)" name='date_naissance' onChange={handleChange} />
            {older ? <span style={{color:'red'}}>Le concours  est ouvert aux bacheliers âgés de 22 ans au maximum en 2023.. </span>: null}
            <InputF className="rounded-left mb-2" label="Copie carte d'identité ou Passport" type='file' name='' onChange={(e) => handleFileChange(e,"copie_carte_id")} />
            {(selectedFiles["copie_carte_id"]?.size > maxFileSize) ? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null}
            <Input type='text' label="Pays de Naissance" name='pays' onChange={handleChange} />
            <Input type='text' label="Lieu de naissance" name='lieu_naissance' onChange={handleChange} />
            <InputT type='number' label="Téléphone" name='telephone' onChange={handleChange} />
            {!valid.isValidPhoneNumber ? <span style={{color:'red'}}>{messageAlerte} </span>: null}
            <Input type='text' label="Adresse" name='address' onChange={handleChange} />
            <Input className="rounded-left mb-2" type='text' label="Ville" name='ville' onChange={handleChange} />
             <Form.Group id='serie'>
              <Form.Label className='fw-medium'>Série au Baccalauréat <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Select name='serie' required value={dataForm.serie} onChange={handleChange}>
                <option value="">--- Choisir une série ---</option>
                <option>S1</option>
                <option>S1A</option>
                <option>S2</option>
                <option>S2A</option>
                <option>S3</option>
                <option>L2</option>
                <option>STEG</option>
                <option>F6</option>
                <option>S4</option>
                <option>S5</option>
                <option>T1</option>
                <option>F6</option>
                <option>L1</option>
                <option>L'1</option>
                <option>LA</option>
                <option>T2</option>
                <option>STIDD</option>
              </Form.Select>
           </Form.Group>
           {/* choix 1 obligatoire  */}
           <Form.Group id='ecole'>
              <Form.Label className='fw-medium'>Choix de l'école <span style={{color:'red'}}>*</span> </Form.Label>
              <Form.Select name='premier_choix_ecole' required value={dataForm.premier_choix_ecole}  className='mb-2'  onChange={handleChange}>
                <option value="" > {((dataForm.serie === 'L2' || dataForm.serie === 'S4' || dataForm.serie === 'S5' || dataForm.serie === 'S3' || dataForm.serie === 'F6' ||
                dataForm.serie === 'S1' || dataForm.serie === 'S2' || dataForm.serie === 'S1A' || dataForm.serie === 'S2A') && 
                    (dataForm.serie !== null)) ? 
                    "Choix 1": " --- Choisir un établissement ---"}
                </option>
                {(dataForm.serie === 'S1' || dataForm.serie === 'S1A' || dataForm.serie === 'S2' || dataForm.serie === 'S2A' || dataForm.serie === 'S3') ?
                     <>
                     <InputOption disabled={true ? (dataForm.second_choix_ecole === "ESTI" ||dataForm.troisieme_choix_ecole === "ESTI" ) : null}  value="ESTI" label="Ecole Supérieure des Sciences et Techniques de l'Ingénieur (ESTI)" />
                     <InputOption disabled={true ? (dataForm.second_choix_ecole === "HEEG" ||dataForm.troisieme_choix_ecole === "HEEG" ) : null} value="HEEG" label="Haute Ecole d'Economie et de Gestion (HEEG)" />
                     <InputOption disabled={true ? (dataForm.second_choix_ecole === "ESMGE" ||dataForm.troisieme_choix_ecole === "ESMGE" ) : null} value="ESMGE" label="Ecole Supérieure des Mines de la Géologie et de l'Environnement (ESMGE)" />
                     <InputOption disabled={true ? (dataForm.second_choix_ecole === "ES2A" ||dataForm.troisieme_choix_ecole === "ES2A" ) : null} value="ES2A" label="Ecole Supérieure des Sciences Agricoles et de l'Alimentation (ES2A)" />
                     <InputOption disabled={true ? (dataForm.second_choix_ecole === "ESU2A" ||dataForm.troisieme_choix_ecole === "ESU2A" ) : null} value="ESU2A" label="Ecole Supérieure de l'Urbanisme d'Architecture et d'Aménagement du Territoire (ESU2A)" />
                     </>
                    : 
                    null
                }
                {(dataForm.serie === 'S4' || dataForm.serie === 'S5') ?
                     <>
                     <InputOption disabled={true ? (dataForm.second_choix_ecole === "ESTI" ||dataForm.troisieme_choix_ecole === "ESTI" ) : null} value="ESTI" label="Ecole Supérieure des Sciences et Techniques de l'Ingénieur (ESTI)" />
                     <InputOption disabled={true ? (dataForm.second_choix_ecole === "ESMGE" ||dataForm.troisieme_choix_ecole === "ESMGE" ) : null} value="ESMGE" label="Ecole Supérieure des Mines de la Géologie et de l'Environnement (ESMGE)" />
                     <InputOption disabled={true ? (dataForm.second_choix_ecole === "ES2A" ||dataForm.troisieme_choix_ecole === "ES2A" ) : null} value="ES2A" label="Ecole Supérieure des Sciences Agricoles et de l'Alimentation (ES2A)" />
                     <InputOption disabled={true ? (dataForm.second_choix_ecole === "ESU2A" ||dataForm.troisieme_choix_ecole === "ESU2A" ) : null} value="ESU2A" label="Ecole Supérieure de l'Urbanisme d'Architecture et d'Aménagement du Territoire (ESU2A)" />
                     </>
                    : 
                    null
                }
                {(dataForm.serie === 'F6') ?
                    <>
                    <InputOption disabled={true ? (dataForm.second_choix_ecole === "ESTI" ||dataForm.troisieme_choix_ecole === "ESTI" ) : null} value="ESTI" label="Ecole Supérieure des Sciences et Techniques de l'Ingénieur (ESTI)" />
                    <InputOption disabled={true ? (dataForm.second_choix_ecole === "HEEG" ||dataForm.troisieme_choix_ecole === "HEEG" ) : null} value="HEEG" label="Haute Ecole d'Economie et de Gestion (HEEG)" />
                    <InputOption disabled={true ? (dataForm.second_choix_ecole === "ESMGE" ||dataForm.troisieme_choix_ecole === "ESMGE" ) : null}  value="ESMGE" label="Ecole Supérieure des Mines de la Géologie et de l'Environnement (ESMGE)" />
                    <InputOption disabled={true ? (dataForm.second_choix_ecole === "ESU2A" ||dataForm.troisieme_choix_ecole === "ESU2A" ) : null} value="ESU2A" label="Ecole Supérieure de l'Urbanisme d'Architecture et d'Aménagement du Territoire (ESU2A)" />
                    </>
                    : 
                    null
                }
                {(dataForm.serie === 'T1' || dataForm.serie === 'T2') ?
                    <InputOption disabled={true ? (dataForm.second_choix_ecole === "ESTI" ||dataForm.troisieme_choix_ecole === "ESTI" ) : null} value="ESTI" label="Ecole Supérieure des Sciences et Techniques de l'Ingénieur (ESTI)" />
                    : 
                    null
                }
                {(dataForm.serie === 'STEG') ?
                    <InputOption disabled={true ? (dataForm.second_choix_ecole === "HEEG" ||dataForm.troisieme_choix_ecole === "HEEG" ) : null} value="HEEG" label="Haute Ecole d'Economie et de Gestion (HEEG)" />
                    : 
                    null
                }
                {(dataForm.serie === 'L2') ?
                    <>
                        <InputOption disabled={true ? (dataForm.second_choix_ecole === "HEEG" ||dataForm.troisieme_choix_ecole === "HEEG" ) : null} value="HEEG" label="Haute Ecole d'Economie et de Gestion (HEEG)" />
                        <InputOption disabled={true ? (dataForm.second_choix_ecole === "ESU2A" ||dataForm.troisieme_choix_ecole === "ESU2A" ) : null} value="ESU2A" label="Ecole Supérieure de l'Urbanisme d'Architecture et d'Aménagement du Territoire (ESU2A)" />
                    </>
                    : 
                    null
                }
                {(dataForm.serie === 'L1' || dataForm.serie === 'L\'1' || dataForm.serie === 'LA' || dataForm.serie === 'STIDD')  ?
                    <InputOption disabled={true ? (dataForm.second_choix_ecole === "ESU2A" ||dataForm.troisieme_choix_ecole === "ESU2A" ) : null} value="ESU2A" label="Ecole Supérieure de l'Urbanisme d'Architecture et d'Aménagement du Territoire (ESU2A)" />
                    : 
                    null
                }
                
              </Form.Select>
              {/* Gerer les autres choix 2*/}
              {(dataForm.premier_choix_ecole &&  ((dataForm.serie === 'S1' || dataForm.serie === 'L2' || dataForm.serie === 'S1A' || dataForm.serie === 'S2' || dataForm.serie === 'S2A' || dataForm.serie === 'S4' || dataForm.serie === 'S3' || dataForm.serie === 'S5' || dataForm.serie === 'F6'))) ? 
            <>
                <Form.Select name='second_choix_ecole' value={dataForm.second_choix_ecole} className='mb-2' onChange={handleChange}>
                    <option value="" >Choix 2 </option>
                    {(dataForm.premier_choix_ecole === 'ESTI' || dataForm.premier_choix_ecole === 'ES2A' || dataForm.premier_choix_ecole === 'ESMGE') ?
                        <>
                        <InputOption disabled={true ? (dataForm.premier_choix_ecole === "ESTI" || dataForm.troisieme_choix_ecole === "ESTI" ) : null}  value="ESTI" label="Ecole Supérieure des Sciences et Techniques de l'Ingénieur (ESTI)" />
                        <InputOption disabled={true ? (dataForm.premier_choix_ecole === "ESMGE" || dataForm.troisieme_choix_ecole === "ESMGE" ) : null} value="ESMGE" label="Ecole Supérieure des Mines de la Géologie et de l'Environnement (ESMGE)" />
                        {dataForm.serie !== 'F6' ? <InputOption disabled={true ? (dataForm.premier_choix_ecole === "ES2A" || dataForm.troisieme_choix_ecole === "ES2A" ) : null} value="ES2A" label="Ecole Supérieure des Sciences Agricoles et de l'Alimentation (ES2A)" /> : null }
                        </>
                        : 
                        null
                    }
                    {(dataForm.premier_choix_ecole ==="HEEG" || dataForm.premier_choix_ecole ==="ESU2A") ?
                        <>
                        <InputOption disabled={true ? (dataForm.premier_choix_ecole === "HEEG" || dataForm.troisieme_choix_ecole === "HEEG" ) : null} value="HEEG" label="Haute Ecole d'Economie et de Gestion (HEEG)" />
                        <InputOption disabled={true ? (dataForm.premier_choix_ecole === "ESU2A" || dataForm.troisieme_choix_ecole === "ESU2A" ) : null} value="ESU2A" label="Ecole Supérieure de l'Urbanisme d'Architecture et d'Aménagement du Territoire (ESU2A)" />
                        </>
                        : 
                        null
                    }
                </Form.Select>

            </> : null}
            {(dataForm.second_choix_ecole && dataForm.second_choix_ecole !== "ESU2A" && dataForm.second_choix_ecole !== "HEEG") ? 
               <>
                    {(dataForm.second_choix_ecole &&  (dataForm.serie === 'S1' || dataForm.serie === 'S1A' || dataForm.serie === 'S2' || dataForm.serie === 'S2A' || dataForm.serie === 'S4' || dataForm.serie === 'S3' || dataForm.serie === 'S5')) ? 
                        <>
                        <Form.Select name='troisieme_choix_ecole' required value={dataForm.troisieme_choix_ecole} onChange={handleChange}>
                                <option value="" >Choix 3 </option>
                            {(dataForm.second_choix_ecole === 'ESTI' || dataForm.second_choix_ecole === 'ES2A' || dataForm.second_choix_ecole === 'ESMGE') ?
                                    <>
                                        <InputOption disabled={true ? (dataForm.premier_choix_ecole === "ESTI" || dataForm.second_choix_ecole === "ESTI" ) : null}  value="ESTI" label="Ecole Supérieure des Sciences et Techniques de l'Ingénieur (ESTI)" />
                                        <InputOption disabled={true ? (dataForm.premier_choix_ecole === "ESMGE" || dataForm.second_choix_ecole === "ESMGE" ) : null} value="ESMGE" label="Ecole Supérieure des Mines de la Géologie et de l'Environnement (ESMGE)" />
                                        {dataForm.serie !== 'F6' ? <InputOption disabled={true ? (dataForm.premier_choix_ecole === "ES2A" || dataForm.troisieme_choix_ecole === "ES2A" ) : null} value="ES2A" label="Ecole Supérieure des Sciences Agricoles et de l'Alimentation (ES2A)" /> : null }
                                    </>
                                    : 
                                null
                            }
                        </Form.Select>
                        </> 
                        : null }
               </> 
                : null
            }
            </Form.Group>
           <Form.Group>
              <Form.Label className='fw-medium'>Région <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Select name='region' required value={dataForm.region} onChange={handleChange}>
                <option value="">--- Choisir une région ---</option>
                <option>Dakar</option>
                <option>Kaolack</option>
                <option>Thies</option>
                <option>Diourbel</option>
                <option>Saint-Louis</option>
                <option>Louga</option>
                <option>Ziguinchor</option>
                <option>Matam</option>
                <option>Kaffrine</option>
                <option>Fatick</option>
                <option>Kolda</option>
                <option>Kedougou</option>
                <option>Tambacounda</option>
                <option>Sédhiou</option>
              </Form.Select>
           </Form.Group>
           {(dataForm.region) ?
              <Form.Group>
                <Form.Label className='fw-medium'>Département <span style={{color:'red'}}>*</span></Form.Label>
                <Form.Select name='departement' value={dataForm.departement} required onChange={handleChange}>
                  <option value="">--- Choisir un département ---</option>
                  {dataForm.region ==="Kaolack" ?
                      <>
                      <option>Kaolack</option>
                      <option>Guinguénéo</option>
                      <option>Nioro Du Rip</option>
                      </>
                  : null}
                  {dataForm.region ==="Dakar" ?
                      <>
                      <option>Dakar</option>
                      <option>Guediawaye</option>
                      <option>Pikine</option>
                      <option>Keur Massar</option>
                      <option>Rufisque</option>
                      </>
                  : null}
                  {dataForm.region ==="Thies" ?
                      <>
                      <option>Thies</option>
                      <option>Tivaouane</option>
                      <option>Mbour</option>
                      </>
                  : null}
                  {dataForm.region ==="Diourbel" ?
                      <>
                      <option>Diourbel</option>
                      <option>Bambey</option>
                      <option>Mbacke</option>
                      </>
                  : null}
                  {dataForm.region ==="Saint-Louis" ?
                      <>
                      <option>Saint-Louis</option>
                      <option>Podor</option>
                      <option>Dagana</option>
                      </>
                  : null}
                  {dataForm.region ==="Louga" ?
                      <>
                      <option>Louga</option>
                      <option>Kébémer</option>
                      <option>Linguère</option>
                      </>
                  : null}
                  {dataForm.region ==="Ziguinchor" ?
                      <>
                      <option>Ziguinchor</option>
                      <option>Oussouye</option>
                      <option>Bignona</option>
                      </>
                  : null}
                  {dataForm.region ==="Matam" ?
                      <>
                      <option>Matam</option>
                      <option>Kanel</option>
                      <option>Ranérou</option>
                      </>
                  : null}
                  {dataForm.region ==="Kaffrine" ?
                      <>
                      <option>Kaffrine</option>
                      <option>Birkilane</option>
                      <option>Koungheul</option>
                      <option>Malem Hodar</option>
                      </>
                  : null}
                  {dataForm.region ==="Fatick" ?
                      <>
                      <option>Fatick</option>
                      <option>Gossas</option>
                      <option>Foundiougne</option>
                      </>
                  : null}
                  {dataForm.region ==="Kolda" ?
                      <>
                      <option>Kolda</option>
                      <option>Vélingara</option>
                      <option>Médina Yoro Foulah</option>
                      </>
                  : null}
                  {dataForm.region ==="Kedougou" ?
                      <>
                      <option>Kedougou</option>
                      <option>Salemata</option>
                      <option>Saraya</option>
                      </>
                  : null}
                  {dataForm.region ==="Tambacounda" ?
                      <>
                      <option>Tambacounda</option>
                      <option>Koumpentoum</option>
                      <option>Bakel</option>
                      <option>Goudiry</option>
                      </>
                  : null}
                  {dataForm.region ==="Sédhiou" ?
                      <>
                      <option>Sédhiou</option>
                      <option>Goudomp</option>
                      <option>Bounkiling</option>
                      </>
                  : null}
                </Form.Select>
            </Form.Group>
            :
            null
            }
            <Input className="rounded-left mb-2" label="Lycée ou école d'origine" type='text' name='lycee_ecole' onChange={handleChange} />

             <Input type='number' min={ dataForm.serie === 'L2' && (dataForm.premier_choix_ecole === 'HEEG' || dataForm.second_choix_ecole === 'HEEG') ? 12 : 0} max={20} label="Moyenne en classe de Terminale" name='moyenne_terminal' onChange={handleChange} />
             {(dataForm.serie === 'L2' &&( dataForm.premier_choix_ecole === 'HEEG' || dataForm.second_choix_ecole === 'HEEG' )  && dataForm.moyenne_terminal < 12) ? 
                <div style={{ marginTop: '10px' }}>
                    <span style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }}>
                        La moyenne en classe de terminale doit être supérieure ou égale à 12/20 pour postuler à cette école (HEEG).
                    </span>
                </div>
                : null
            }
            <Input type='number' min={0} max={20} label="Moyenne générale en classe de Première" name='moyenne_premiere' onChange={handleChange} />
            <Input type='number' min={0} max={20} label="Moyenne générale en classe de Seconde" name='moyenne_seconde' onChange={handleChange} />
            <Form.Group>
              <Form.Label className='fw-medium'>Session au BAC * </Form.Label>
              <Form.Select name='session' required value={dataForm.session} onChange={handleChange}>
                <option value="">Sélectionner  ...</option>
                <option value="normale">Normale</option>
                <option value = "second">Second Tour</option>
              </Form.Select>
           </Form.Group>
            <Input type='number' min={0} max={20} label="Moyenne BAC - 1er Tour" name='moyenne_bac' onChange={handleChange} />
            <InputT type='number' min={0} label="Numéro de Table de BAC" name='numero_table' onChange={handleChange} />
            <Form.Group>
              <Form.Label className='fw-medium'>Choix du Centre de Concours <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Select name='centre_examen' required value={dataForm.centre_examen} onChange={handleChange}>
                <option value="">--- Veuiller choisir un centre ---</option>
                <option value="Diamniadio (UAM)">Diamniadio (UAM)</option>
                <option value="Diourbel">Diourbel</option>
                <option value="Kaolack">Kaolack</option>
                <option value="Kédougou">Kédougou</option>
                <option value="Matam">Matam</option>
                <option value="Saint-Louis">Saint-Louis</option>
                <option value="Tambacounda">Tambacounda</option>
                <option value="Thiès">Thiès</option>
                <option value="Ziguinchor">Ziguinchor</option>
                <option value="Podor">Podor</option>
                <option value="Louga">Louga</option>
                <option value="Kolda">Kolda</option>
              </Form.Select>
            </Form.Group>
            <InputF className="rounded-left mb-2" label="Relevé de Note BAC" type='file' name='' onChange={(e) => handleFileChange(e,"releve_bac")} />
            {(selectedFiles["releve_bac"]?.size > maxFileSize) ? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null}
            <InputF className="rounded-left mb-2" label="Bulletins terminale" type='file' name='' onChange={(e) => handleFileChange(e,"bulletin_terminal")} />
            { gestionfichier.bulletin_terminal2 ? <>
                {isInputVisible ? <>
                <InputF className="rounded-left mb-2" label="Bulletin terminale 2" type='file' name='' onChange={(e) => handleFileChange(e,"bulletin_terminal2")} /></> : null }
                <Button variant="outline-success float-end" onClick={toggleInputVisibility}>
                {isInputVisible ? 'Annuler' : 'Ajouter un nouveau fichier'}
                </Button> <br />
                {(selectedFiles["bulletin_terminal2"]?.size > maxFileSize) ? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null}<br />
                </> : 
                null
            }
            {(selectedFiles["bulletin_terminal"]?.size > maxFileSize) ? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null}
            <InputF className="rounded-left mb-2" label="Bulletins Première" type='file' name='' onChange={(e) => handleFileChange(e,"bulletin_premiere")} />
            { gestionfichier.bulletin_premiere2 ? <>
                {isInputVisible2 ? <>
                <InputF className="rounded-left mb-2" label="Bulletin Première 2" type='file' name='' onChange={(e) => handleFileChange(e,"bulletin_premiere2")} /></> : null }
                <Button variant="outline-success float-end" onClick={toggleInputVisibility2}>
                {isInputVisible2 ? 'Annuler' : 'Ajouter un nouveau fichier'}
                </Button> <br />
                {(selectedFiles["bulletin_premiere2"]?.size > maxFileSize) ? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null}
                </> : 
                null
            }
             {(selectedFiles["bulletin_premiere"]?.size > maxFileSize) ? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null}
           <InputF className="rounded-left mb-2" label="Bulletins Seconde" type='file' name='' onChange={(e) => handleFileChange(e,"bulletin_seconde")} />
            { gestionfichier.bulletin_seconde2 ? <>
                {isInputVisible3 ? <>
                <InputF className="rounded-left mb-2" label="Bulletin Seconde 2" type='file' name='' onChange={(e) => handleFileChange(e,"bulletin_seconde2")} /></> : null }
                <Button variant="outline-success float-end" onClick={toggleInputVisibility3}>
                {isInputVisible3 ? 'Annuler' : 'Ajouter un nouveau fichier'}
                </Button> <br />
                {(selectedFiles["bulletin_seconde2"]?.size > maxFileSize) ? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null} <br />
                </> : 
                null
            }
            {(selectedFiles["bulletin_seconde"]?.size > maxFileSize) ? <span style={{color:'red'}}>taille du fichier {'>'} 1 Mo veuiller le réduire . </span>: null}
            <Button  variant="primary" type='submit' size="md"
                className="p-2 float-end mb-2">
                Envoyer {submitted ?
                    <Spinner animation="border"
                      size="sm" role="status"

                    >

                    <span className="visually-hidden">Loading...</span>
                    </Spinner>
                      : null}
            </Button>
            {complete}
            <Link to='/'>
            <Button variant='primary' size='md' 
                className='float-start mb-2'>
                    Annuler
                </Button>
            </Link>
          </Form>
        </div>
        </div>
      </div>
    </>
  )
}

export default Depot