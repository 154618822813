import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle} from '@fortawesome/free-solid-svg-icons';
function RecepisseConcours ({dataPerson}) {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
  
    return (
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header >
          <Modal.Title>Candidature enregisrée</Modal.Title>
          <FontAwesomeIcon icon={faCheckCircle} className="green-icon m-2" size="lg" />
          </Modal.Header>
          <Modal.Body>
              <div className="container ">
              <div className='text-dark text-centered'>
                  Votre candidature a été enregistrée. 
          </div>
          <div>
                Un mail de confirmation vous sera envoyé à  : <span className='fw-bold'>{dataPerson?.email}</span> 
          </div>
              </div>
          </Modal.Body>
          <Modal.Footer>
          <Link to='/'>
                <Button variant="outline-success" onClick={handleClose}>
                  Fermer
                </Button>
                </Link>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
  export default RecepisseConcours