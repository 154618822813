import React, {useContext, useEffect} from 'react'
import { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { Container, Modal, Button, Spinner } from 'react-bootstrap'
import axios from 'axios'
import CredsContext from "../../context/CredsContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
const IdTransaction = ({show, handleClose, email}) => {
    const navigate = useNavigate();
    const [facture, setFacture] = useState(false);
    const {creds, setCreds}= useContext(CredsContext);
    const [enabled, setEnabled] = useState(false);
    const [stopRequests, setStopRequests] = useState(false);
    useEffect (() => {
      // console.log(email)
      if (show === true && !stopRequests) {
        // console.log('this is okaaay')
        axios({
          url:"https://depot.uam.sn/api/v1/paiement/waiting/",
          method: 'POST',
          data: {
              email : email
        }
      })
      .then(result => {
          if (result?.data?.message) {
              console.log("hello")
          } else {
               console.log('id Transaction:', result?.data.etat);
               setFacture(result?.data);
               setCreds({
                   ...creds,
                   email: result?.data?.email,
                   num_transaction: result?.data?.num_transaction
               })
               setEnabled(true);
               if (result.data.etat === 0) {
                setStopRequests(true);
               }
          }
      })
      .catch((error) => {
          console.log('Error:', error);
      });
      }
       }, [setFacture, email, show,creds, setCreds,stopRequests])
  return (
    <>
    <Container>
    <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
        {!facture ? <Modal.Title>Facture de paiement </Modal.Title> : <><Modal.Title>Candidature enregistrée</Modal.Title><FontAwesomeIcon icon={faCheckCircle} className="green-icon m-2" size="lg" /></> }
        </Modal.Header>
        <Modal.Body>{facture ? <div>
          <div className='text-dark text-centered'>
                  Votre candidature a été enregistrée. 
          </div>
          <div>
                Un mail de confirmation vous sera envoyé à  : <span className='fw-bold'>{facture?.email}</span> 
          </div>
        </div> : <div className='text text-success fw-medium'> Veuiller valider le paiement en suivant les instructions reçues par SMS <FontAwesomeIcon icon="fa-duotone fa-spinner" /> <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner> </div>  }
    </Modal.Body>
        <Modal.Footer>
        <Link to='/'>
              <Button variant="" onClick={handleClose}>
                Fermer
              </Button>
              </Link>
        </Modal.Footer>
      </Modal>
    </Container>
    </>
  )
}

export default IdTransaction