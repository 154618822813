import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Form,Spinner,Image} from 'react-bootstrap';
import { useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import Input from '../../components/Input';
import {InputT,InputF} from '../../components/Input';
import AlerteMessage from '../../components/AlerteMessage';
import { faBan } from "@fortawesome/free-solid-svg-icons";
import RecepisseConcours from '../../components/Recepisse';
import wip from '../../img/wip.png';
// import Image from 'react-bootstrap';
const bacSeries = ['S1', 'STEG', 'L2','L\'1','S1A','S2','S2A','S3','S4','S5','T1','L1a','F6','T2'];
const departments = {
    'scientifique': ['ST2AN', 'DSTI', 'DGAE',],
    'litteraire': ['DGO', 'DU2ADT'],
};
const filieres = {
    'S1': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DGO': ['Management des Organisations', 'Finance Comptabilité'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'S1A': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DGO': ['Management des Organisations', 'Finance Comptabilité'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'S2': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DGO': ['Management des Organisations', 'Finance Comptabilité'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'S2A': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DGO': ['Management des Organisations', 'Finance Comptabilité'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'S3': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DGO': ['Management des Organisations', 'Finance Comptabilité'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'S4': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'S5': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'T1': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
    },
    'T2': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
    },
    'L2': {
        'DGO': ['Management des Organisations', 'Finance Comptabilité'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'STEG': {
        'DGO': ['Management des Organisations', 'Finance Comptabilité'],
    },
    'L\'1': {
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'L1a': {
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
    'F6': {
        'ST2AN': ['Sciences Agricole et de l\'Alimentation'],
        'DSTI': ['Ingénierie Informatique', 'Infrastructures et Génie Civil','Génie de Procédés','Électronique et télécommunications','Systèmes Électriques et Énergétiques'],
        'DGAE': ['Environnement', 'Mines et géologie','Hydraulique et assainissement','Géomatique'],
        'DU2ADT': ['Urbanisme et Aménagement', 'Architecture'],
    },
};
const UpdateDepot = ({showUpdate,handleCloseUpdate}) => {
    // Donnés de choix pour une meilleur optimisation
    const [bacSerie, setBacSerie] = useState('');
    const [ok, setOK] = useState(false);
    const [orderedChoices, setOrderedChoices] = useState([]);
    const [choices, setChoices] = useState({});
    const [totalChoices, setTotalChoices] = useState(0);
    const [firstChoiceType, setFirstChoiceType] = useState('');
    const handleSerieChange = (e) => {
        data.premier_choix_ecole = '';
        data.second_choix_ecole = '';
        data.troisieme_choix_ecole = '';
        data.quatrieme_choix_ecole = '';
        setBacSerie(datas.serie);
        setData({ ...data, 'serie': e.target.value});
        setChoices({});
        setOrderedChoices([]);
        setTotalChoices(0);
        setFirstChoiceType('');
    };
    var choix_par_ordre = ['premier_choix_ecole', 'second_choix_ecole', 'troisieme_choix_ecole','quatrieme_choix_ecole'];
const handleFiliereChange = (department, filiere, deptType) => {
    setChoices((prevChoices) => {
        const deptChoices = prevChoices[department] || [];
        let newChoices;
        let newTotalChoices = totalChoices;

        if (deptChoices.includes(filiere)) {
            // Supprimer le choix
            const newDeptChoices = deptChoices.filter((f) => f !== filiere);
            newTotalChoices -= 1;
            newChoices = { ...prevChoices, [department]: newDeptChoices };

            // Mise à jour de l'ordre des choix
            const updatedOrderedChoices = orderedChoices.filter(choice => !(choice.department === department && choice.filiere === filiere));
            setOrderedChoices(updatedOrderedChoices);

            // Mise à jour de l'état des données
            const updatedData = { ...data };
            choix_par_ordre.forEach((key, index) => {
                // console.log(key,index)
                updatedData[key] = updatedOrderedChoices[index]?.filiere || '';
            });
            setData(updatedData);
        } else {
            if (newTotalChoices < 4 && deptChoices.length < 2) {
                if (newTotalChoices === 0) {
                    setFirstChoiceType(deptType);
                } else if (firstChoiceType && firstChoiceType !== deptType) {
                    return prevChoices;
                }
                const newDeptChoices = [...deptChoices, filiere];
                newTotalChoices += 1;
                newChoices = { ...prevChoices, [department]: newDeptChoices };

                // Ajouter le nouveau choix à la fin de l'ordre des choix
                const updatedOrderedChoices = [...orderedChoices, { department, filiere }];
                setOrderedChoices(updatedOrderedChoices);

                // Mise à jour de l'état des données
                const updatedData = { ...data };
                choix_par_ordre.forEach((key, index) => {
                    updatedData[key] = updatedOrderedChoices[index]?.filiere || '';
                });
                setData(updatedData);
            } else {
                return prevChoices;
            }
        }
        setTotalChoices(newTotalChoices);
        return newChoices;
    });
};

const renderDepartments = (deptType) => {
    return departments[deptType].map((department) => (
        <div key={department}>
            {/* <span className='fw-medium'>{department}</span> */}
            {department ==='ST2AN' ? <span className='fw-medium'>Sciences et Techniques Agricoles, Alimentaires et Nutritionnelles</span> : null }
            {department ==='DSTI' ? <span className='fw-medium'>Sciences et techniques de l'ingénieur</span> : null }
            {department ==='DGAE' ? <span className='fw-medium'>Géosciences Appliquées et Environnement</span> : null }
            {department ==='DU2ADT' ? <span className='fw-medium'>Urbanisme Architecture et Aménagement Durable des Territoires</span> : null }
            {department ==='DGO' ? <span className='fw-medium'>Gestion des Organisations</span> : null }
            {filieres[bacSerie][department] && filieres[bacSerie][department].map((filiere) => (
                <div key={filiere} className='form-check'>
                    <input
                        type="checkbox"
                        className='form-check-input'
                        id={`${department}-${filiere}`}
                        value={filiere}
                        checked={choices[department]?.includes(filiere) || false}
                        onChange={() => handleFiliereChange(department, filiere, deptType)}
                        disabled={
                            (!choices[department]?.includes(filiere) && totalChoices >= 4) ||
                            (firstChoiceType && firstChoiceType !== deptType) || (!choices[department]?.includes(filiere) && totalChoices >= 2 && deptType === 'litteraire')
                        }
                    />
                    <label className='form-check-label' htmlFor={`${department}-${filiere}`}>{filiere}</label>
                </div>
            ))}
        </div>
    ));
};
const [selectedFiles, setSelectedFiles] = useState({
    "releve_bac":true,
    "copie_carte_id" : true,
    "bulletin_terminal" : true,
    "bulletin_premiere" : true,
    "bulletin_seconde" : true,
    "bulletin_premiere2" : true,
    "bulletin_seconde2" : true,
    "bulletin_terminal2" : true,
});
const [gestionfichier, setGestionFichier] = useState({  
    "bulletin_premiere2" : false,
    "bulletin_seconde2" : false,
    "bulletin_terminal2" : false
});
    const [open, setOpen] = useState (false);
    const [message, setMessage] = useState (false);
    const [older,setOlder] = useState(false);
    const maxFileSize = 1024 * 1024;
    const [valid, SetIsValid] = useState({
        isValidEmail: false,
        isValidPhoneNumber: false,
      });
    const [show, setShow] = useState(false);
    const[ messageAlerte, setMessageAlerte] = useState('');
    const [errors, setErrors] = useState(false);
    const [showPay, setShowPay] = useState(false);
    const [complete, setComplete] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [step, setStep] = useState(1);
    const [photo, setPhoto] = useState(false);
    const [requiredOK, setRequiredOk] = useState(true);
const handleFileChange = (event, key) => {
    const elements = document.getElementsByName(key)
    let file = event.target.files[0];
    if (file && file.type !== 'application/pdf') {
        setSelectedFiles((prevSelectedFiles) => ({
            ...prevSelectedFiles,
            [key]: false
        }));
        setGestionFichier((prevSelectedFiles) => ({
            ...prevSelectedFiles,
            [key]: false
        }));
        setPhoto(true);
        setOpen(true)
        setMessage("Seul le format pdf est autorisé !")
        event.target.value = '';
        return ;
    }
    if (file?.size < maxFileSize){
        if (key !== 'photo') {
            if (file.type ==='application/pdf') {
                if (connected) {
                    data.nom = data.nom ? data.nom : datas.nom
                    data.prenom = data.prenom ? data.prenom : datas.prenom
                    console.log(data.nom,data.prenom)
                }
                // console.log(data.prenom.trim());
                if (!data.nom || !data.prenom) {
                setOpen(true)
                setMessage("Veuiller renseigner d'abord le nom et prénom !");
                event.target.value = '';
                return
                }
                data.nom = data.nom.trim()
                data.prenom = data.prenom.trim()
                var filesinfos = file.slice(0, file.size, 'application/pdf'); 
                file = new File([filesinfos], `${key+"_"+data.prenom.replace(/\s+/g, '_')+"_"+data.nom.replace(/\s+/g, '')}.pdf`, {type: 'application/pdf'});
                setPhoto(false)
                setSelectedFiles((prevSelectedFiles) => ({
                    ...prevSelectedFiles,
                    [key]: file
                }));
                setGestionFichier((prevSelectedFiles) => ({
                    ...prevSelectedFiles,
                    [key]: true
                }));
                for (var i = 0; i < elements.length; i++) {
                    var element1 = elements[i];
                    element1.style.border = "";
                }
                console.log(file)
            } else {
                setPhoto(true)
                setOpen(true)
                setMessage("format de fichier non permis")
                // console.log("format de fichier non permis ")
                setGestionFichier((prevSelectedFiles) => ({
                    ...prevSelectedFiles,
                    [key]: false
                }));
                return
            }   
        } else {
            // console.log(file)
            setSelectedFiles((prevSelectedFiles) => ({
                ...prevSelectedFiles,
                [key]: file
            }));
            setGestionFichier((prevSelectedFiles) => ({
                ...prevSelectedFiles,
                [key]: true
            }));
            for (var J = 0; J < elements.length; J++) {
                var element = elements[J];
                element.style.border = ""; // Applique une bordure rouge
            }
        }
    } else {
        // console.log('fichier top gros !')
        setSelectedFiles((prevSelectedFiles) => ({
            ...prevSelectedFiles,
            [key]: false
        }));
        setGestionFichier((prevSelectedFiles) => ({
            ...prevSelectedFiles,
            [key]: false
        }));
        for (var j = 0; j < elements.length; j++) {
            var element2 = elements[j];
            element2.style.border = "2px solid #BC0C0C";
            element2.style.transition ='border 0.5s ease-in-out;' // Applique une bordure roug
        }
        setSelectedFiles((prevSelectedFiles) => ({
            ...prevSelectedFiles,
            [key]: false
        }));
        setGestionFichier((prevSelectedFiles) => ({
            ...prevSelectedFiles,
            [key]: false
        }));
        setPhoto(true);
        setOpen(true)
        setMessage("Le fichier chargé est supérieur à 1 Mo")
        event.target.value = '';
        return ;
    }
};
    const inputFileRef = useRef(null);
    const triggerFileInput = (id) => {
        const dile = document.getElementById(id)
        dile.click()
      };
    // Fin des donnés issus de choix 
    const [succes,setSucces] = useState(false);
    const [depotsuccess, setDeppotSuccess] = useState(false);
    const [data, setData] = useState(''); /* Donnés de formulaire */
    const [datas, setDatas] = useState(''); /* Donnés de formulaire */
    const [connected, setConnected] = useState(false);
    const [failedConnexion, setfailedConnexion] = useState(false);
    const handleChange = (e) => {
        const name= e.target.name;
        let value = e.target.value;
        if (name==="nom") {
            let nom  = value.toUpperCase()
            value = nom
            // console.log(nom)
        }
        setData({ ...data, [name]: value })
    }
    const donnes = {
        "email" : data.email
    }
    async function handleSubmit (e) {
        e.preventDefault();
        if (connected) {
            try {
                e.preventDefault();
                const formData = new FormData();
                console.log("premier choix ecole ", firstChoiceType)
                data.nom = data.nom ? data.nom : datas.nom
                data.prenom = data.prenom ? data.prenom : datas.prenom
                data.prenom = data.prenom.trim()
                data.nom = data.nom.trim()
                // console.log("prenom"+ data.prenom + "nom:"+ data.nom + 'ok')
                // formData.append('campagne','concours')
                data.serie = datas.serie
                const nameFiles = ["releve_bac","copie_carte_id","bulletin_terminal","bulletin_premiere","bulletin_seconde","bulletin_terminal2","bulletin_premiere2","bulletin_seconde2"]
                for (const i in nameFiles) {
                    if (selectedFiles[nameFiles[i]].size) {
                        // console.log('fichier bien rempli')
                        if ((selectedFiles[nameFiles[i]]).size <= maxFileSize) {
                            formData.append(nameFiles[i] , selectedFiles[nameFiles[i]])
                        } else {
                            // console.log("fichier trop gros");
                        }
                    }
                    // else {
                    //     setPhoto(true);
                    //     setOpen(true);
                    //     setMessage("le document "+ nameFiles[i]+ " est obligatoire !");
                    //     setErrors(true);
                    //     return
                    // }
                }
                if (!data.premier_choix_ecole) {
                    formData.append('premier_choix_ecole' ,datas.premier_choix_ecole)
                }
                if (!data.second_choix_ecole) {
                    formData.append('second_choix_ecole' ,datas.second_choix_ecole)
                }
                formData.append('num_transaction' ,datas.paiement.num_transaction)
                if (!data.date_naissance) {
                    formData.append('date_naissance' ,datas.date_naissance)
                }
                for (let key in data) {
                    if (key!=='table_bac') {
                        if (data.hasOwnProperty(key)) {
                            if( data[key] !== null || data[key] !== '') {
                                if (data[key] === '' || data[key] === null) {
                                    if ((key ==='troisieme_choix_ecole' || key ==='quatrieme_choix_ecole') & (firstChoiceType==='litteraire'))  {
                                        data.troisieme_choix_ecole = '';
                                        data.quatrieme_choix_ecole = '';
                                    } else {
                                        setPhoto(true);
                                        setOpen(true);
                                        setMessage("le champs "+ key + " est obligatoire.");
                                        setErrors(true);
                                        return
                                    }
                                }
                                formData.append(key ,data[key])
                            } else {
                                // console.log(data[key])
                            }
                        }
                    }
                    
                }
                 for (let pair of formData.entries()) {
                    // console.log(pair[0] + ':' + pair[1]);
                } 
                const fieldsCount = Array.from(formData.entries()).length;
                // console.log("Champs remplis coorrectement "+fieldsCount)
                if (!data.serie ) {
                    setPhoto(true);
                    setOpen(true);
                    setMessage("les choix sont obligatooire");
                    // console.log("les choix sont obligatoires")
                }
                else if (data.premier_choix_ecole==='true' || data.second_choix_ecole==='false' || data.premier_choix_ecole==='false' || data.second_choix_ecole==='true') {
                    setPhoto(true);
                    setOpen(true);
                    setMessage("les choix sont obligatooire");
                    // console.log("les choix sont obligatoires")
                }
                else if ((firstChoiceType === 'scientifique') & (data.premier_choix_ecole='' || data.second_choix_ecole==='' || data.troisieme_choix_ecole==='' || data.quatrieme_choix_ecole==='')) {
                    setPhoto(true);
                    setOpen(true);
                    setMessage("les choix sont obligatooire");
                    console.log("les choix sont obligatoires")
                }
                else if ((firstChoiceType === 'litteraire') & (data.premier_choix_ecole='' || data.second_choix_ecole==='')) {
                    setPhoto(true);
                    setOpen(true);
                    setMessage("les choix sont obligatooire");
                    console.log("les choix sont obligatoires")
                }
                else {
                    setErrors(false)
                    setComplete(true)
                    setSubmitted(true);
                    console.log("Requete en cours d'envoi ...")
                    const response = await axios.post(
                        'https://depot.uam.sn/api/v1/candidat/update',
                        formData,
                {
                        headers: {
                        'Content-Type': 'multipart/form-data',
                        },
                        });
    
                if (response.status === 200) {
                    setSubmitted(false);
                // console.log("Initialisation Success");
                // console.log('Data from API :', response.data);
                if (response.data.data) {
                    setOK(true)
                    setDeppotSuccess(true)
                    setSucces(response.data.data)
                    // console.log(response.data)
                }
                else if (response.data.detail) {
                        setSubmitted(false);
                        setOpen(true);
                        setMessage(response.data.detail)
                        return
                }
                } else {
                    if (response.data.data) {
                        setDeppotSuccess(true)
                        setSucces(response.data.data)
                        // console.log(response.data)
                        return
                    }
                    // console.error('Error uploading files');
                }
            }
        } catch (error) {
            setSubmitted(false);
            setOpen(true)
            setMessage('Veuiller vérifier les choix de filère !')
            // console.error('Error uploading files', error);
        }
        } else {
            try {
                const response = await axios.post(`https://depot.uam.sn/api/v1/paiement/confirm/?table_bac=${data.table_bac}`, donnes)
                // console.log(response)
                if (response.status === 200) {
                    if (response.data.detail) {
                        setfailedConnexion(true);
                        alert('connexion failed')
                    } else {
                        setfailedConnexion(false);
                        setConnected(true);
                        setDatas(response.data);
                        // setData(response.data)
                        console.log(datas)
                    }
                } 
            } catch(error) {
                console.error(error)
            }
        }
    }
    useEffect(()=> {
        if (ok) {
            handleCloseUpdate();
        }
        if (showUpdate === false) {
            setData('');
            setDatas('');
            setConnected(false);
        }
        if (connected) {
            setBacSerie(datas.serie);
            // console.log(bacSerie);
        }
        
        // console.log(data);
        // console.log(selectedFiles)
    }, [data,setData,showUpdate,bacSerie,setBacSerie,datas,connected,selectedFiles,succes,ok,setOK,handleCloseUpdate])
    const handleClose3 = () => {
        setOpen(false)
    }
  return (
    <>
        <AlerteMessage open={open} handleClose={handleClose3} message={message} />
        {depotsuccess ? <RecepisseConcours  dataPerson={succes}/> : null}
      <Modal
        show={showUpdate}
        onHide={handleCloseUpdate}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        {/* <Modal.Title>Mon dossier</Modal.Title> */}
        {/* <FontAwesomeIcon icon={faCheckCircle} className="green-icon m-2" size="lg" /> */}
        </Modal.Header>
        <Modal.Body>
            <div className="container p-3 fs-5">
                {connected ?
                <>
                <Form onSubmit={handleSubmit} className=''>
                    <div className='mb-2' id='connexionDossier'>
                        <div className='text-dark text-centered'>
                            <Form.Group className='col-md-6 col-sm-12 mb-2'>
                                <Form.Control 
                                    placeholder='support@uam.edu.sn'
                                    size='md'
                                    type='email'
                                    name='email'
                                    className='p-2 custom-email-input'
                                    onChange={handleChange}
                                    defaultValue = {datas.email}
                                />
                            </Form.Group>
                            {/* <Form.Group className='col-md-6 col-sm-12'>
                                <Form.Control 
                                    placeholder='Numéro de table BAC'
                                    size='md'
                                    type='number'
                                    name='numero_table'
                                    defaultValue = {datas.numero_table}
                                    className='p-2 custom-email-input'
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group> */}
                            <Form.Group className='col-md-6 col-sm-12'>
                            <Form.Label className='fw-medium'>Prénom</Form.Label>
                                <Form.Control 
                                    size='md'
                                    type='text'
                                    name='prenom'
                                    defaultValue = {datas.prenom}
                                    className='p-2 custom-email-input'
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className='col-md-6 col-sm-12'>
                            <Form.Label className='fw-medium'>Nom</Form.Label>
                                <Form.Control 
                                    size='md'
                                    type='text'
                                    name='nom'
                                    defaultValue = {datas.nom}
                                    className='p-2 custom-email-input'
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Input type='date' className='col-md-6 col-sm-12' label="Date de Naissance (jour/mois/année)" name='date_naissance' defalut={datas.date_naissance || ''} onChange={handleChange} />
                            <Input type='text' className='col-md-6 col-sm-12' label="Pays de Naissance" name='pays' defalut={datas.pays} onChange={handleChange} />
                            <Input type='text' className='col-md-6 col-sm-12' label="Lieu de naissance" name='lieu_naissance' defalut={datas.lieu_naissance} onChange={handleChange} />
                            <Input type='text' className='col-md-6 col-sm-12' label="Telephone" name='telephone' defalut={datas.telephone} onChange={handleChange} />
                            <Input type='text' className='col-md-6 col-sm-12' label="Adresse" name='address' defalut={datas.address} onChange={handleChange} />
                            <Input type='text' className='col-md-6 col-sm-12' label="Ville" name='ville' defalut={datas.ville} onChange={handleChange} />
                            <Input className="rounded-left mb-2" label="Lycée ou école d'origine" type='text' name='lycee_ecole' defalut={datas.lycee_ecole} onChange={handleChange} />
                                <Input type='number' max={20} min={ datas.serie === 'L2' && (datas.premier_choix_ecole === 'HEEG' || datas.second_choix_ecole === 'HEEG') ? 12 : 0} label="Moyenne en classe de Terminale" name='moyenne_terminal' defalut={datas.moyenne_terminal} onChange={handleChange} />
                                {(datas.serie === 'L2' &&( datas.premier_choix_ecole === 'HEEG' || datas.second_choix_ecole === 'HEEG' )  && datas.moyenne_terminal < 12) ? 
                                    <div style={{ marginTop: '10px' }}>
                                        <span style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }}>
                                            La moyenne en classe de terminale doit être supérieure ou égale à 12/20 pour postuler à cette école (HEEG).
                                        </span>
                                    </div>
                                    : null
                                }
                                <Input type='number' min={0} max={20} label="Moyenne générale en classe de Première" name='moyenne_premiere' defalut={datas.moyenne_premiere} onChange={handleChange} />
                                <Input type='number' min={0} max={20} label="Moyenne générale en classe de Seconde" name='moyenne_seconde' defalut={datas.moyenne_seconde} onChange={handleChange} />
                                <div className=''>
                                {/* <label className="form-label fw-medium">Série de BAC:</label> */}
                                {/* <select value={data.serie} onChange={handleSerieChange} className='form-select'>
                                    <option value="">Sélectionner une série</option>
                                    {bacSeries.map((serie) => (
                                        <option className='t' key={serie} value={serie}>{serie}</option>
                                    ))}
                                </select> */}
                            </div>
                                <Form.Group>
                                <Form.Label className='fw-medium'>Session au BAC <span style={{color:'red'}}>*</span> </Form.Label>
                                <Form.Select name='session' required value={datas.session} onChange={handleChange}>
                                    <option value="">Sélectionner  ...</option>
                                    <option value="normale">Premier Tour</option>
                                    <option value = "second">Deuxième Tour</option>
                                </Form.Select>
                            </Form.Group>
                                <Input type='number' min={0} max={20} label="Moyenne BAC - 1er Tour" name='moyenne_bac' defalut={datas.moyenne_bac} onChange={handleChange} />
                                <InputT type='number' min={0} label="Numéro de Table de BAC" name='numero_table' defalut={datas.numero_table} onChange={handleChange} />
                                {/* Infors du concours */}
                                
                                <div className="head">
                                <span className='text-bold'>Informations du concours</span> 
                            </div>
                                <Form.Group>
                                <Form.Label className='fw-medium'>Choix du Centre de Concours <span style={{color:'red'}}>*</span></Form.Label>
                                <Form.Select name='centre_examen' required defaultValue={datas.centre_examen} onChange={handleChange}>
                                    <option value="">--- Veuiller choisir un centre ---</option>
                                    <option value="Diamniadio (UAM)">Diamniadio (UAM)</option>
                                    <option value="Diourbel">Diourbel</option>
                                    <option value="Kaolack">Kaolack</option>
                                    <option value="Kédougou">Kédougou</option>
                                    <option value="Matam">Matam</option>
                                    <option value="Saint-Louis">Saint-Louis</option>
                                    <option value="Tambacounda">Tambacounda</option>
                                    <option value="Thies">Thies</option>
                                    <option value="Ziguinchor">Ziguinchor</option>
                                    <option value="Podor">Podor</option>
                                    <option value="Louga">Louga</option>
                                    <option value="Kolda">Kolda</option>
                                </Form.Select>
                                </Form.Group>
                                {bacSerie && (
                                    <>
                                        <Form.Label className='fw-medium'>Choix de filières<span style={{color:'red'}}>*</span></Form.Label>
                                        <div id='ChoixFilières'>
                                        {bacSerie && (
                                            <>
                                                {(!firstChoiceType || firstChoiceType === 'scientifique') && renderDepartments('scientifique')}
                                                {(!firstChoiceType || firstChoiceType === 'litteraire') && renderDepartments('litteraire')}
                                            </>
                                        )}
                                        <span>Mes Nouveaux choix:</span>
                                        <ul>
                                            {orderedChoices.map((choice, index) => (
                                                <li key={`${choice.filiere}`}>
                                                    <span>Choix {index + 1}</span> : {choice.filiere}
                                                </li>
                                            ))}
                                        </ul>
                                        </div>
                                        <span>Mes anciens choix </span>
                                        <ul>
                                            <li>{datas.premier_choix_ecole}</li>
                                            <li>{datas.second_choix_ecole}</li>
                                            <li>{datas.troisieme_choix_ecole}</li>
                                            <li>{datas.quatrieme_choix_ecole}</li>
                                        </ul>
                                    </>
                            )}                                         
                                <InputF id="copie_carte_id" className="rounded-left mb-2" label="Copie carte d'identité / copie passport / carte scolaire" type='file' name='copie_carte_id' onChange={(e) => handleFileChange(e, "copie_carte_id")} />
                                {!selectedFiles["copie_carte_id"] ? <span style={{color: 'red'}}>taille du fichier {'>'} 1 Mo veuillez le réduire.</span> : null}

                                <InputF id="releve_bac" className="rounded-left mb-2" label="Relevé BAC" type='file' name='releve_bac' onChange={(e) => handleFileChange(e, "releve_bac")} />
                                {!selectedFiles["releve_bac"] ? <span style={{color: 'red'}}>taille du fichier {'>'} 1 Mo veuillez le réduire.</span> : null}

                                <InputF id="bulletin_terminal" className="rounded-left mb-2" label="Bulletin terminale premier semestre" type='file' name='bulletin_terminal' onChange={(e) => handleFileChange(e, "bulletin_terminal")} />
                                {!selectedFiles["bulletin_terminal"] ? <span style={{color: 'red'}}>taille du fichier {'>'} 1 Mo veuillez le réduire.</span> : null}

                                <InputF id="bulletin_terminal2" className="rounded-left mb-2" label="Bulletin terminale deuxieme semestre" type='file' name='bulletin_terminal2' onChange={(e) => handleFileChange(e, "bulletin_terminal2")} />
                                {!selectedFiles["bulletin_terminal2"] ? <span style={{color: 'red'}}>taille du fichier {'>'} 1 Mo veuillez le réduire.</span> : null}

                                <InputF id="bulletin_premiere" className="rounded-left mb-2" label="Bulletin Première premier semestre" type='file' name='bulletin_premiere' onChange={(e) => handleFileChange(e, "bulletin_premiere")} />
                                {!selectedFiles["bulletin_premiere"] ? <span style={{color: 'red'}}>taille du fichier {'>'} 1 Mo veuillez le réduire.</span> : null}

                                <InputF id="bulletin_premiere2" className="rounded-left mb-2" label="Bulletin Première deuxieme semestre" type='file' name='bulletin_premiere2' onChange={(e) => handleFileChange(e, "bulletin_premiere2")} />
                                {!selectedFiles["bulletin_premiere2"] ? <span style={{color: 'red'}}>taille du fichier {'>'} 1 Mo veuillez le réduire.</span> : null}

                                <InputF id="bulletin_seconde" className="rounded-left mb-2" label="Bulletin Seconde premier semestre" type='file' name='bulletin_seconde' onChange={(e) => handleFileChange(e, "bulletin_seconde")} />
                                {!selectedFiles["bulletin_seconde"] ? <span style={{color: 'red'}}>taille du fichier {'>'} 1 Mo veuillez le réduire.</span> : null}

                                <InputF id="bulletin_seconde2" className="rounded-left mb-2" label="Bulletin Seconde deuxieme semestre" type='file' name='bulletin_seconde2' onChange={(e) => handleFileChange(e, "bulletin_seconde2")} />
                                {!selectedFiles["bulletin_seconde2"] ? <span style={{color: 'red'}}>taille du fichier {'>'} 1 Mo veuillez le réduire.</span> : null}

                            
                            <Button variant='success' type='submit' role='submit'>Modifier son dossier</Button>
                        </div>
                        </div>
                    </Form>
                    {errors ? <><div className="alert alert-danger" role="alert">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                                </svg> Veuiler remplir tous les champs
                            </div></> : null}
                            {/* <Button
                            size='md'
                            variant='primary' 
                            disabled={!valid.isValidEmail || !valid.isValidPhoneNumber || older  || photo }
                            type='submit'
                            role='submit'
                            className ='float-end btn-submit '
                            >
                                {requiredOK ? (<span>Payer avec {data.operateur}</span>)  : <FontAwesomeIcon icon={faBan} className='color-logo' />} 
                                {submitted ? 
                                    <Spinner animation="grow"
                                        size="sm" role="status"
                                    >
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner> 
                                : null} 
                        </Button> */}
                </> : 
                    <Form onSubmit={handleSubmit} className=''>
                    <div className='mb-2' id='connexionDossier'>
                        <div className='text-dark text-centered'>
                            {/* <Form.Group className='col-md-6 col-sm-12 mb-2'>
                                <Form.Control 
                                    placeholder='support@uam.edu.sn'
                                    size='md'
                                    type='email'
                                    name='email'
                                    className='p-2 custom-email-input'
                                    onChange={handleChange}
                                    value={data.email || ''}
                                    required = {true}
                                />
                            </Form.Group> */}
                            <div className='alert alert-warning'>
                            <span>Les dépots vont bientôt commencer ..</span> <br />
                            <span className=''>Veuiller patienter</span>
                            </div>
                            <div style={{marginLeft : '30%'}}>
                            <Image src={wip} height={200} width={180} alt="wip" />
                            </div>
                            {/* <Form.Group className='col-md-6 col-sm-12'>
                                <Form.Control 
                                    placeholder='Numéro de table BAC'
                                    size='md'
                                    type='number'
                                    name='table_bac'
                                    value={data.table_bac || ''}
                                    className='p-2 custom-email-input'
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group> */}
                            {failedConnexion ? <span className='' style={{"color":"red"}}>Votre dossier n'est pas enregistré</span> : null}
                            <div id="buttonsValid">
                            {/* <Button 
                                variant='success' 
                                type='submit' 
                                role='submit'
                            >Consulter mon dossier {submitted ? 
                                <Spinner animation="grow"
                                    size="sm" role="status"
                                >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> 
                            : null}</Button> */}
                            </div>
                            
                        </div>
                        </div>
                    </Form>
                }
            </div>
        </Modal.Body>
        {/* <Modal.Footer>
              <Button variant="" onClick={handleCloseUpdate}>
                Fermer
              </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  )
}

export default UpdateDepot