import { createContext, useState } from "react";

const CandidatContext = createContext({});

export const CandidatProvider =  ({children}) => {
    const [candidatContext, setCandidatContext] = useState({
        candidats: [],
        currentCandidat: {}
    });
    return (
        <CandidatContext.Provider value={{candidatContext, setCandidatContext}}>
            {children}
        </CandidatContext.Provider>
    )
}

export default CandidatContext;